import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import ShareButton from "./ShareButton";
import donateImage from "../assets/donate now.gif.mp4"

const DonorList = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

  const url = process.env.REACT_APP_API_URL + `/donors?populate=*`;
  const headers = {
    "Authorization": "Bearer " + process.env.REACT_APP_API_TOKEN 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });
        setData(data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [url]);

  useEffect(() => {
    if (Data) {
      const total = Data.reduce((acc, donor) => acc + donor.attributes.amount, 0);
      setTotalAmount(total);
    }
  }, [Data]);

  const progressBarPercentage = Math.min((totalAmount / 200000) * 100, 100);
  const numberOfDonors = Data?.length || 0;

  return (
    <div className="w-full max-w-[350px] h-auto p-[10px] bg-white border-white border-2 shadow-lg rounded-lg sticky mb-4">
      
      <div className='flex flex-row'>
          <div className='mt-8 mr-4'>
            <div>
            <h1 className=' text-lg text-darkgreen font-sans font-black'>{formatter.format(totalAmount)} raised</h1>
          </div>
          <div className='flex flex-row'>
            <h1 className='text-gray px-1 font-sans'>$200,000 goal</h1>
            &bull;
            <p className="text-gray px-1 font-sans">{numberOfDonors} donations</p>
          </div>
          </div>
          <div className="my-3 flex justify-center">
        <div className="relative">
          <svg className="w-[100px] h-[100px]">
            <circle
              className="text-gray"
              strokeWidth="8"
              stroke="currentColor"
              fill="transparent"
              r="45"
              cx="50"
              cy="50"
            />
            <circle
              className="text-green"
              strokeWidth="8"
              strokeDasharray="283"
              strokeDashoffset={283 - (283 * progressBarPercentage) / 100}
              strokeLinecap="round"
              stroke="currentColor"
              fill="transparent"
              r="45"
              cx="50"
              cy="50"
              style={{ transition: 'stroke-dashoffset 5s ease' }}
            />
          </svg>
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <span className="text-lg font-bold text-darkgreen">{Math.round(progressBarPercentage)}%</span>
          </div>
        </div>
      </div>
      </div>

      <div className="flex flex-col">
        <ShareButton />
        {/* <button 
          className="font-sans w-full bg-buttongreen px-6 py-1 mt-1 rounded-md text-white self-end"
          onClick={() => {
            navigate("/donate");
            window.scrollTo(0, 0);
          }}
        >
          Donate now
        </button> */}
                                <video
                                autoPlay
                                muted
                                loop
                                className="object-contain self-stretch w-[80%] h-[100%] mx-auto mt-6 rounded-full"
                                onClick={() => {
                                  navigate("/donate");
                                  window.scrollTo(0, 0);
                                }}
                                >
                                <source src={donateImage} type="video/mp4" />
                                Your browser does not support the video tag.
                                </video>
      </div>

      <div className="pt-3 pb-[3%]">
        <h2 className="text-greenshade text-[1.5rem] font-sans">List of Donors</h2>
      </div>
      <div className="pb-[20px] flex gap-1 h-[19rem] overflow-y-auto">
        <div className="w-full cursor-pointer">
          {Data?.map(x => (
            <div key={x.id} className="text-greenshade">
              <h5 className="font-sans text-lg">
                {x.attributes?.firstName} {x.attributes?.lastName} {formatter.format(x.attributes?.amount)}
              </h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonorList;
