import React, { useEffect } from "react";
import ProgressBar from "../../components/ProgressBar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { url } from "../../data";
import { useNavigate } from 'react-router-dom';
import bglo from "../../assets/Logo/bglo.png";
import NextProject from "./NextProject";
import Donate from "./donors";


export default function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log("project detail", id);
  const [project, setProject] = useState(null);

  const [Data, setData] = useState(null);

  const url = process.env.REACT_APP_API_URL + `/projects/${id}?populate=*`;
  const headers = {
    Authorization: "bearer" + process.env.REACT_APP_API_TOKEN,
  };
  console.log(url, "url");

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      console.log(data?.data, "blog error");

      setData(data?.data);
      setProject(data.data.attributes?.name);
      console.log(data.data.attributes?.name);

      console.log(Data, " Data");
    };

    fetchData();
  }, [url]);

  return (
    <>
      <div className="flex flex-col md:flex-col lg:flex-row h-[100rem] md:h-[80rem] lg:h-[100%]">
        <div className="col-span-3 mt-px sm:row-span-1">
          <img
            loading="lazy"
            src={process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.right_img_2.data[0].attributes?.url
            }
            className="object-contain object-center w-full overflow-hidden mt-24"
          />
        </div>
        <div className="p-[1rem] xl:w-[100%] md:w-[100%] lg:w-[100%]  pt-20 flex flex-col md:flex-row lg:flex-col">
          <div className="mb-3">
          <NextProject />
          </div>
          <div className="h-auto w-full">
          <Donate project={project} />
          </div>
        </div>
      </div>
    </>
  );
}
