import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";

const CareerPage = () => {
  const [careerData, setCareerData] = useState([]);
  const [showFullText, setShowFullText] = useState({});

  const fetchData = async () => {
    try {
    //   const response = await axios.get("http://localhost:1337/api/career-page?populate=*");
    const response = await axios.get(process.env.REACT_APP_API_URL + `/career-pages?populate=*`);
      setCareerData(response.data.data);
    } catch (error) {
      console.error("Error fetching career data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleShowFullText = (id) => {
    setShowFullText((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const cardVariants = {
    rest: { opacity: 1, scale: 1 },
    hover: { scale: 1.05 },
  };

  return (
    <div className="mx-auto p-5 max-w-6xl">
      {careerData.map((item) => (
        <motion.div
          key={item.id}
          className="bg-white rounded-lg shadow-lg p-6 mb-10"
          initial="rest"
          whileHover="hover"
          variants={cardVariants}
        >
          <img
            src={process.env.REACT_APP_API_IMAGE_URL + item.attributes?.image?.data[0].attributes?.url || ""}
            alt={item.attributes.name}
            className="w-full h-auto rounded-md mb-4"
          />
          <h2 className="text-2xl font-semibold mb-2 text-darkgren">
            {item.attributes.name}
          </h2>
          <div
            className={`text-darkgren text-1xl ${
              showFullText[item.id] ? "" : "line-clamp-6"
            } max-md:max-w-full max-md:mt-10`}
          >
            <p className="text-base text-gray-600 font-sans text-justify">
              {item.attributes.description}
            </p>
            {/* {item.attributes.additionalDetails && (
              <>
                <h1 className="mt-4 font-bold">Additional Details:</h1>
                <p>{item.attributes.additionalDetails}</p>
              </>
            )} */}
            {/* <p className="mt-4 font-sans text-justify">
              To Apply: Click below to submit a Missions Inquiry if you are
              interested in this opportunity. For questions, please contact our
              Missionary Director, at Volunteer@constantinefoundation.org
            </p> */}
          </div>
          {!showFullText[item.id] && (
            <button
              onClick={() => toggleShowFullText(item.id)}
              className="w-[25%] bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded mt-4"
            >
              Read More
            </button>
          )}
        </motion.div>
      ))}
    </div>
  );
};

export default CareerPage;
