import React, { useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { CiLinkedin, CiFacebook } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";

const ShareButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUrl = window.location.href;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    alert("Link copied to clipboard!");
  };

  const toggleShareOptions = () => {
    setIsOpen(!isOpen);
  };

  const closeShareOptions = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {/* Share Button */}
      <button
        className="font-sans w-full bg-buttongreen px-6 py-7 rounded-md text-white self-end"
        onClick={toggleShareOptions}
      >
        Share
      </button>

      {/* Dropdown for Share Options */}
      {isOpen && (
        <div className="absolute left-16 mt-2 w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-10">
          <div className="p-4 flex flex-col gap-2">
            {/* Close Button */}
            <button
              className="self-end text-sm text-gray-500 hover:text-gray-700"
              onClick={closeShareOptions}
            >
              Close
            </button>
            <hr className="my-2" />
            {/* Copy Link */}
            <button
              className="w-full text-left text-sm text-gray-700 px-4 py-2 rounded hover:bg-gray-100"
              onClick={handleCopyLink}
            >
              Copy Link
            </button>
            <hr className="my-2" />
            {/* Social Media Links */}
            <a
              href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                currentUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full text-sm text-blue-500 hover:text-blue-600 px-4 py-2 flex items-center gap-2"
            >
              <span><FaXTwitter /></span> Share on Twitter
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                currentUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full text-sm text-blue-600 hover:text-blue-700 px-4 py-2 flex items-center gap-2"
            >
              <span><CiFacebook /></span> Share on Facebook
            </a>
            <a
              href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                currentUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full text-sm text-blue-700 hover:text-blue-800 px-4 py-2 flex items-center gap-2"
            >
              <span><CiLinkedin /></span> Share on LinkedIn
            </a>
            <a
              href={`mailto:?subject=Check this out&body=${encodeURIComponent(currentUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full text-sm text-blue-700 hover:text-blue-800 px-4 py-2 flex items-center gap-2"
            >
              <span><MdOutlineMail /></span> Share on Email
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
