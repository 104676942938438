import React, { useState } from 'react';
import axios from 'axios';

const CareerForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cv: null // For the file upload
  });

  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      cv: e.target.files[0] // Save the uploaded file
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Step 1: Upload the CV file
      const filePayload = new FormData();
      filePayload.append('files', formData.cv);
      
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        filePayload,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          }
        }
      );
  
      if (uploadResponse.status !== 200 && uploadResponse.status !== 201) {
        throw new Error('File upload failed');
      }
  
      const uploadedFileId = uploadResponse.data[0].id;
  
      // Step 2: Submit the form data with the uploaded file ID
      const formPayload = {
        data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          cv: uploadedFileId, // Reference the uploaded file by ID
        },
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/careers`,
        formPayload,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          }
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        console.log('Form submitted successfully:', response.data);
        setFormStatus('success');
  
        // Clear form fields
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          cv: null
        });
        document.getElementById('cv').value = null;
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      console.error(error.response ? error.response.data : 'Error', error.message);
      setFormStatus('error');
    }
  };
  

  return (
    <div className="mx-auto my-4 w-96">
      {formStatus === 'success' && (
        <div className="mb-4 text-green font-semibold">
          Application submitted successfully!
        </div>
      )}
      {formStatus === 'error' && (
        <div className="mb-4 text-red-600 font-semibold">
          There was an error submitting the form. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label htmlFor="firstName" className="block text-darkgreen text-sm font-bold mb-2 text-left">First Name:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="text" 
          name="firstName" 
          id="firstName" 
          value={formData.firstName} 
          onChange={handleChange} 
          required
        /><br />
        
        <label htmlFor="lastName" className="block text-darkgreen text-sm font-bold mb-2 text-left">Last Name:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="text" 
          name="lastName" 
          id="lastName" 
          value={formData.lastName} 
          onChange={handleChange} 
          required
        /><br />
        
        <label htmlFor="email" className="block text-darkgreen text-sm font-bold mb-2 text-left">Email:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="email" 
          name="email" 
          id="email" 
          value={formData.email} 
          onChange={handleChange} 
          required
        /><br />
        
        <label htmlFor="cv" className="block text-darkgreen text-sm font-bold mb-2 text-left">Upload CV:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="file" 
          name="cv" 
          id="cv" 
          onChange={handleFileChange} 
          accept=".pdf,.doc,.docx"
          required
        /><br /><br />
        
        <button 
          className="bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded" 
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CareerForm;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import emailjs from 'emailjs-com';

// const CareerForm = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     cv: null // For the file upload
//   });

//   const [formStatus, setFormStatus] = useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       cv: e.target.files[0] // Save the uploaded file
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const formPayload = new FormData();
//       formPayload.append('firstName', formData.firstName);
//       formPayload.append('lastName', formData.lastName);
//       formPayload.append('email', formData.email);
//       if (formData.cv) {
//         formPayload.append('cv', formData.cv);
//       }

//       // Send the form data using EmailJS
//       const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
//       const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
//       const userID = process.env.REACT_APP_EMAILJS_USER_ID;

//       const emailParams = {
//         firstName: formData.firstName,
//         lastName: formData.lastName,
//         email: formData.email,
//       };

//       if (formData.cv) {
//         const reader = new FileReader();
//         reader.readAsDataURL(formData.cv);
//         reader.onload = async () => {
//           emailParams.cv = reader.result;
//           try {
//             await emailjs.send(serviceID, templateID, emailParams, userID);
//             setFormStatus('success');
//             setFormData({
//               firstName: '',
//               lastName: '',
//               email: '',
//               cv: null
//             });
//             document.getElementById('cv').value = null;
//           } catch (error) {
//             console.error('EmailJS error:', error);
//             setFormStatus('error');
//           }
//         };
//       } else {
//         await emailjs.send(serviceID, templateID, emailParams, userID);
//         setFormStatus('success');
//         setFormData({
//           firstName: '',
//           lastName: '',
//           email: '',
//           cv: null
//         });
//         document.getElementById('cv').value = null;
//       }
//     } catch (error) {
//       console.error('Submission error:', error);
//       setFormStatus('error');
//     }
//   };

//   return (
//     <div className="mx-auto my-4 w-96">
//       {formStatus === 'success' && (
//         <div className="mb-4 text-green font-semibold">
//           Application submitted successfully!
//         </div>
//       )}
//       {formStatus === 'error' && (
//         <div className="mb-4 text-red-600 font-semibold">
//           There was an error submitting the form. Please try again.
//         </div>
//       )}
//       <form onSubmit={handleSubmit}>
//         <label htmlFor="firstName" className="block text-darkgreen text-sm font-bold mb-2 text-left">First Name:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="text" 
//           name="firstName" 
//           id="firstName" 
//           value={formData.firstName} 
//           onChange={handleChange} 
//           required
//         /><br />
        
//         <label htmlFor="lastName" className="block text-darkgreen text-sm font-bold mb-2 text-left">Last Name:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="text" 
//           name="lastName" 
//           id="lastName" 
//           value={formData.lastName} 
//           onChange={handleChange} 
//           required
//         /><br />
        
//         <label htmlFor="email" className="block text-darkgreen text-sm font-bold mb-2 text-left">Email:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="email" 
//           name="email" 
//           id="email" 
//           value={formData.email} 
//           onChange={handleChange} 
//           required
//         /><br />
        
//         <label htmlFor="cv" className="block text-darkgreen text-sm font-bold mb-2 text-left">Upload CV:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="file" 
//           name="cv" 
//           id="cv" 
//           onChange={handleFileChange} 
//           accept=".pdf,.doc,.docx"
//           required
//         /><br /><br />
        
//         <button 
//           className="bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded" 
//           type="submit"
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// export default CareerForm;
