import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError } from "../utils";
import { Store } from "../Store";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

function ProductScreen() {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  const euro = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR'
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;

  const navigate = useNavigate();
  const { state: locationState } = useLocation(); // Access passed state
  const { productName } = locationState || {}; // Destructure product name from state

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/products?filters[name][$eq]=${productName}&populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        setProduct(data);
        setLoading(false);
      } catch (err) {
        setError(getError(err));
        setLoading(false);
      }
    };

    const imageUrl = `${process.env.REACT_APP_API_IMAGE_URL}${productData?.imageURL?.data[0].attributes?.url}`;
console.log(imageUrl);


    if (productName) {
      fetchData();
    } else {
      setError("No product name provided.");
      setLoading(false);
    }
  }, [productName]);

  const addToCartHandler = async () => {
    try {
      const existItem = cart.cartItems.find((x) => x.id === product?.data?.[0]?.id);
      const quantity = existItem ? existItem.quantity + 1 : 1;

      if (product?.data?.[0]?.attributes?.countInStock < quantity) {
        window.alert("Sorry. Product is out of stock");
        return;
      }

      ctxDispatch({
        type: "CART_ADD_ITEM",
        payload: {
          ...product?.data?.[0]?.attributes,
          id: product?.data?.[0]?.id,
          quantity,
        },
      });
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };

  const productData = product?.data?.[0]?.attributes || {};

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className="rounded-xl bg-white mt-16">
      <div className="flex gap-10 p-8 items-start">
        {/* Product Image */}
        <div className="w-full md:w-1/3 flex items-center">
          <img
            className="w-full h-[25rem] rounded-lg"
            src={`${process.env.REACT_APP_API_IMAGE_URL}${productData?.imageURL?.data[0].attributes?.url}`}
            alt={productData?.name}
          />
        </div>

        {/* Product Details */}
        <div className="w-full md:w-5/12 pr-4 border-r-4 border-greenshade">
          <ul className="list-none">
            <li>
              <h1 className="text-3xl mb-4 border-b-2 pb-2 border-green">
                {productData?.name}
              </h1>
            </li>
            <li>
              <div className="mb-4 border-b-2 pb-2 border-green">
                <span className="text-gray text-sm mr-5">Price:</span>
                <span className="text-green text-lg">
                  {formatter.format(productData?.price)}&nbsp;|&nbsp;{euro.format(productData?.gbp)}
                </span>
              </div>
            </li>
            <li>
              <div className="mb-4 pb-2 border-b-2 border-green">
                <span className="text-gray-600 text-sm mr-5">Status:</span>
                {productData?.countInStock > 0 ? (
                  <span className="bg-green p-1 px-2 rounded-lg text-white">
                    In Stock
                  </span>
                ) : (
                  <span className="text-red-600 text-lg">Unavailable</span>
                )}
              </div>
            </li>
            <li>
              <div className="mb-4">
                <span className="text-gray text-sm">Description:</span>
                <p className="text-sm font-sans font-semibold">{productData?.description}</p>
              </div>
            </li>
            {productData?.countInStock > 0 && (
              <li>
                <div className="mb-4">
                                <Link
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                  to="/shop/cart"
                                  className="bg-yellow-500 px-4 w-[25%] py-2 rounded-lg flex items-center"
                                >
                                  Order Now
                                  {cart.cartItems.length > 0 && (
                                    <div className="bg-red-500 ml-2 rounded-full px-1 top-0 right-4 text-[0.8rem] text-white ">
                                      {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                                    </div>
                                  )}
                                </Link>
                </div>
              </li>
            )}
            <li>
              <button
                onClick={() => navigate("/shop")}
                className="bg-amber-900 text-white px-4 py-2 rounded-lg"
              >
                Continue Shopping
              </button>
            </li>
          </ul>
        </div>

        {/* Shopping Cart */}
        <div className="w-full md:w-1/4 overflow-y-scroll">
          <h1 className="text-3xl mb-4">Shopping Cart</h1>
          {cart?.cartItems?.length === 0 ? (
            <MessageBox>Cart is empty</MessageBox>
          ) : (
            cart.cartItems.map((item) => (
              <div key={item.id} className="flex flex-col mb-2">
                <div>
                  {/* <Link
                    onClick={() => window.scrollTo(0, 0)}
                    to={`/shop/product/${item.id}`}
                  > */}
                    <img
                      src={`${process.env.REACT_APP_API_IMAGE_URL}${item.attributes?.imageURL?.data[0].attributes?.url}`}
                      alt={item.attributes?.name}
                      className="w-16 h-16 rounded-full"
                    />
                  {/* </Link> */}
                </div>
                <div className="ml-4 text-green">
                  {/* <Link to={`/shop/product/${item.id}`}> */}
                    {item.attributes?.name}
                  {/* </Link> */}
                  {/* <div className="font-sans">X {item.quantity}</div> */}
                  <div>{formatter.format(item.attributes?.price)}</div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductScreen;
