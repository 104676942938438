

import React, { useEffect, useState } from "react";
import axios from "axios";
import ShareButton from "../../components/ShareButton";
import Slider from "react-slick";
import donateImage from "../../assets/donate now.gif.mp4";
import styled from "styled-components";
import { BiBarChartAlt } from "react-icons/bi";
import { CiStar } from "react-icons/ci";


// Modal Component
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-[90%] max-w-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button
            onClick={onClose}
            className="text-green hover:text-red-800 text-2xl font-bold"
          >
            ×
          </button>
        </div>
        <div className="overflow-y-auto max-h-[400px]">{children}</div>
      </div>
    </div>
  );
};

const ProjectCarousel = () => {
  const [projects, setProjects] = useState([]);
  const [donorData, setDonorData] = useState({});
  const [modalContent, setModalContent] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const headers = {
    Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/projects?populate=*`,
          headers,
        });
        setProjects(data?.data || []);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchDonors = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/donors?populate=*`,
          headers,
        });
        const donorMap = data?.data?.reduce((acc, donor) => {
          const project = donor.attributes?.purpose;
          if (!acc[project]) acc[project] = [];
          acc[project].push(donor);
          return acc;
        }, {});
        setDonorData(donorMap || {});
      } catch (error) {
        console.error("Error fetching donor data:", error);
      }
    };

    fetchDonors();
  }, []);

  const calculateProgress = (raised, budget) => {
    return budget > 0 ? Math.min((raised / budget) * 100, 100) : 0;
  };

  const handleSeeAll = (projectName, donors) => {
    setModalTitle(`All Donors for ${projectName}`);
    setModalContent(
      donors.map((donor) => (
        <div key={donor.id} className="py-2">
          {donor.attributes?.firstName} {donor.attributes?.lastName}:{" "}
          {formatter.format(donor.attributes?.amount)}
        </div>
      ))
    );
    setIsModalOpen(true);
  };

  const handleSeeTop = (projectName, donors) => {
    setModalTitle(`Top 5 Donors for ${projectName}`);
    const topDonors = [...donors]
      .sort((a, b) => b.attributes.amount - a.attributes.amount)
      .slice(0, 5);
    setModalContent(
      topDonors.map((donor) => (
        <div key={donor.id} className="py-2">
          {donor.attributes?.firstName} {donor.attributes?.lastName}:{" "}
          {formatter.format(donor.attributes?.amount)}
        </div>
      ))
    );
    setIsModalOpen(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const StyledSlider = styled.div`
  .slick-dots li button:before {
    font-size: 16px;
    color: gray;
  }

  .slick-dots li.slick-active button:before {
    color: black;
  }

  .slick-prev, .slick-next {
    width: 120px;
    height: 120px;
    z-index: 1;
    margin-left: -1px;
    margin-top: -60px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 50px;
    color: black;
    opacity: 0.75;
  }

  .slick-prev:hover:before, .slick-next:hover:before {
    color: green;
  }
`;

  return (
    <StyledSlider>
      <Slider {...sliderSettings}>
        {projects.map((project) => {
          const projectName = project.attributes?.name;
          const projectBudget = project.attributes?.budget || 0;
          const projectGoal = project.attributes?.goal || 0;
          const donors = donorData[projectName] || [];
          const totalAmount = donors.reduce(
            (acc, donor) => acc + donor.attributes.amount,
            0
          );
          const progressBarPercentage = calculateProgress(
            totalAmount,
            projectBudget
          );
          const circumference = 2 * Math.PI * 45;
          const strokeDashoffset =
            circumference - (circumference * progressBarPercentage) / 100;

          return (
            <div
              key={project.id}
              className="w-full max-w-[100%] h-auto p-[10px] bg-white border-white border-2 rounded-lg sticky mb-4"
            >
              {/* Existing content */}
              <div className="text-center text-3xl font-black text-darkgren mb-10">
                <h1>{projectName}</h1>
              </div>
              <div className="flex flex-row">
                <div className="mt-8 mr-4">
                  <h1 className="text-lg text-darkgreen font-sans font-black">
                    {formatter.format(totalAmount)} raised
                  </h1>
                  <div className="flex flex-row">
                    <h1 className="text-gray px-1 font-sans">
                      {formatter.format(projectGoal)} goal
                    </h1>
                    &bull;
                    <p className="text-gray px-1 font-sans">{donors.length} donations</p>
                  </div>
                </div>
                <div className="my-3 flex justify-center">
                  <div className="relative">
                    <svg className="w-[150px] h-[150px]">
                      <circle
                        className="text-gray"
                        strokeWidth="8"
                        stroke="currentColor"
                        fill="transparent"
                        r="45"
                        cx="50"
                        cy="50"
                      />
                      <circle
                        className="text-green"
                        strokeWidth="8"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                        strokeLinecap="round"
                        stroke="currentColor"
                        fill="transparent"
                        r="45"
                        cx="50"
                        cy="50"
                        style={{ transition: 'stroke-dashoffset 0.5s ease' }}
                      />
                    </svg>
                    <div className="absolute -top-7 -left-6 w-full h-full flex items-center justify-center">
                      <span className="text-lg font-bold text-darkgreen">
                        {Math.round(progressBarPercentage)}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col mt-5">
                <ShareButton />
                  <video
                  autoPlay
                  muted
                  loop
                  className="object-contain self-stretch w-[50%] h-[100%] mx-auto mt-6 rounded-full"
                  onClick={() => {
                      window.location.href = `/donate?project=${projectName}`;
                  }}
                  >
                  <source src={donateImage} type="video/mp4" />
                  Your browser does not support the video tag.
                  </video>
                  <div className="flex flex-row mt-16 bg-opacity-80">
                    <div className="justify-center bg-lightgreen text-white p-3 rounded-full mx-2">
                      <BiBarChartAlt />
                    </div>
                    <p>
                      {donors.length} people just donated
                    </p>
                  </div>
              </div>

              <div className="pt-3 pb-[3%]">
                <h2 className="text-greenshade text-[1.5rem] font-sans">List of Donors</h2>
              </div>
              <div className="pb-[20px] flex gap-1 h-[6rem] overflow-y-auto">
                <div className="w-full cursor-pointer">
                  {donors.map((x) => (
                    <div key={x.id} className="text-greenshade">
                      <h5 className="font-sans text-lg">
                        {x.attributes?.firstName} {x.attributes?.lastName} {formatter.format(x.attributes?.amount)}
                      </h5>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-between mt-5">
                <button
                  className="bg-green text-white px-4 py-2 rounded-full"
                  onClick={() => handleSeeAll(projectName, donors)}
                >
                  See All
                </button>
                <button
                  className="bg-green text-white px-4 py-2 rounded-full flex-row"
                  onClick={() => handleSeeTop(projectName, donors)}
                >
                  {/* <CiStar /> */}
                  See Top
                </button>
              </div>
            </div>
          );
        })}
      </Slider>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalTitle}
      >
        {modalContent}
      </Modal>
    </StyledSlider>
  );
};

export default ProjectCarousel;
