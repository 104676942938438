// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: "AIzaSyDaDdAySZfaVb7u6rpjBcMFe_5mvgh0F5M",
//     authDomain: "auth-596da.firebaseapp.com",
//     projectId: "auth-596da",
//     storageBucket: "auth-596da.firebasestorage.app",
//     messagingSenderId: "475821110488",
//     appId: "1:475821110488:web:62070b7be73df4cbfd13ad"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyB6sICzDFgOjVBGkYihEVHHsbzQvXv3qtU",
  authDomain: "constantine-76475.firebaseapp.com",
  projectId: "constantine-76475",
  storageBucket: "constantine-76475.firebasestorage.app",
  messagingSenderId: "783437682036",
  appId: "1:783437682036:web:9213b1f64332df95bd0821"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
