// import React, { useState } from "react";
// import { signInWithPopup, signInWithRedirect } from "firebase/auth";
// import { auth, googleProvider, facebookProvider } from "./firebase";
// import LoginForm from "./Login";
// import SignUpForm from "./Signup";
// import Logo from "../assets/Logo/ConstantionLogoColor.png";

// const Auth = () => {
//     const [isLogin, setIsLogin] = useState(true);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState("");

//     // Unified Sign-In Function
//     const handleSignIn = async (provider) => {
//         setLoading(true);
//         setError("");
//         try {
//             const result = await signInWithPopup(auth, provider);
//             console.log("Sign-In Success:", result.user);
//             // Additional logic: Redirect or save user data
//         } catch (error) {
//             if (error.code === "auth/popup-blocked") {
//                 console.warn("Popup blocked. Using redirect instead.");
//                 try {
//                     await signInWithRedirect(auth, provider);
//                 } catch (redirectError) {
//                     console.error("Redirect Sign-In Error:", redirectError.message);
//                     setError(`Redirect failed: ${redirectError.message}`);
//                 }
//             } else {
//                 console.error("Sign-In Error:", error.message);
//                 setError(error.message);
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="bg-gradient-to-tr from-darkgreen to-greenlight flex flex-col items-center justify-center min-h-screen md:py-2">
//             <main className="flex items-center w-full px-2 md:px-20">
//                 {/* Left Side - Logo */}
//                 <div className="hidden md:inline-flex flex-col flex-1 space-y-1">
//                     <img alt="waving tree" src={Logo} className="w-[70%]" />
//                 </div>

//                 {/* Right Side - Form */}
//                 <div className="flex flex-col space-y-4 w-full max-w-2xl">
//                     {isLogin ? (
//                         <LoginForm setIsLogin={setIsLogin} />
//                     ) : (
//                         <SignUpForm setIsLogin={setIsLogin} />
//                     )}

//                     {/* Error Display */}
//                     {error && (
//                         <div className="text-red-500 text-center">{error}</div>
//                     )}

//                     {/* Social Sign-In Buttons */}
//                     <div className="flex flex-col items-center space-y-2">
//                         <button
//                             onClick={() => handleSignIn(googleProvider)}
//                             className="bg-white text-black border rounded-lg px-4 py-2 w-full flex items-center justify-center"
//                             disabled={loading}
//                         >
//                             <img
//                                 src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
//                                 alt="Google Icon"
//                                 className="w-5 h-5 mr-2"
//                             />
//                             {loading ? "Signing in..." : "Sign in with Google"}
//                         </button>
//                         {/* <button
//                             onClick={() => handleSignIn(facebookProvider)}
//                             className="bg-blue-600 text-white border rounded-lg px-4 py-2 w-full flex items-center justify-center"
//                             disabled={loading}
//                         >
//                             <img
//                                 src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
//                                 alt="Facebook Icon"
//                                 className="w-5 h-5 mr-2"
//                             />
//                             {loading ? "Signing in..." : "Sign in with Facebook"}
//                         </button> */}
//                     </div>
//                 </div>
//             </main>
//         </div>
//     );
// };

// export default Auth;

import React, { useState } from "react";
import { signInWithPopup, signInWithRedirect } from "firebase/auth";
import { auth, googleProvider, facebookProvider } from "./firebase";
import LoginForm from "./Login";
import SignUpForm from "./Signup";
import Logo from "../assets/Logo/ConstantionLogoColor.png";
import Swal from "sweetalert2";

const Auth = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSignIn = async (provider) => {
        setLoading(true);
        setError("");
        try {
            const result = await signInWithPopup(auth, provider);
            console.log("Sign-In Success:", result.user);
            Swal.fire({
              icon: "success",
              title: "Login Successful!",
              html: `<p>Thank you for your generosity! Your donation has been successfully submitted, and the budget has been updated.</p>
                     <p>We appreciate your support in making a difference!</p>`,
              confirmButtonColor: "#28a745",
            });        
        } catch (error) {
            if (error.code === "auth/popup-blocked") {
                console.warn("Popup blocked. Using redirect instead.");
                try {
                    await signInWithRedirect(auth, provider);
                } catch (redirectError) {
                    console.error("Redirect Sign-In Error:", redirectError.message);
                    setError(`Redirect failed: ${redirectError.message}`);
                }
            } else {
                console.error("Sign-In Error:", error.message);
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gradient-to-tr from-darkgreen to-greenlight flex flex-col items-center justify-center min-h-screen px-4 py-6">
            <main className="flex flex-col md:flex-row items-center w-full max-w-6xl space-y-6 md:space-y-0 md:space-x-6">
                {/* Left Side - Logo */}
                <div className="hidden md:flex flex-1 justify-center">
                    <img
                        alt="Constantion Logo"
                        src={Logo}
                        className="w-full max-w-sm"
                    />
                </div>

                {/* Right Side - Form */}
                <div className="flex flex-col flex-1 items-center space-y-6 bg-white p-8 rounded-lg shadow-lg w-full max-w-full">
                    <h1 className="text-2xl font-semibold text-gray-800">
                        {isLogin ? "Welcome Back!" : "Create an Account"}
                    </h1>
                    {isLogin ? (
                        <LoginForm setIsLogin={setIsLogin} />
                    ) : (
                        <SignUpForm setIsLogin={setIsLogin} />
                    )}

                    {/* Error Display */}
                    {error && (
                        <div className="text-red-600 bg-red-100 border border-red-500 rounded-lg p-2 w-full text-center">
                            {error}
                        </div>
                    )}

                    {/* Social Sign-In Buttons */}
                    <div className="w-full space-y-3">
                        <button
                            onClick={() => handleSignIn(googleProvider)}
                            className="flex items-center justify-center bg-white text-black border rounded-lg px-4 py-2 w-full shadow hover:shadow-md transition disabled:opacity-50"
                            disabled={loading}
                        >
                            <img
                                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                alt="Google Icon"
                                className="w-5 h-5 mr-2"
                            />
                            {loading ? "Signing in..." : "Sign in with Google"}
                        </button>
                        {/* Uncomment Facebook button if needed */}
                        {/* <button
                            onClick={() => handleSignIn(facebookProvider)}
                            className="flex items-center justify-center bg-blue-600 text-white rounded-lg px-4 py-2 w-full shadow hover:shadow-md transition disabled:opacity-50"
                            disabled={loading}
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                                alt="Facebook Icon"
                                className="w-5 h-5 mr-2"
                            />
                            {loading ? "Signing in..." : "Sign in with Facebook"}
                        </button> */}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Auth;
