import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar";
import { motion } from "framer-motion";
import ProjectReport from "./DownloadsSection";
import asset1 from "../../assets/rfinancial operations report (2).png";
import axios from "axios";
import { Link } from "react-router-dom";
import NextProject from "./NextProject";
// import Doner from '../../components/donors';
import ProjectCarousel from "../../components/campaign/ProjectCarousel";

export default function Project() {
  return (
    <div>
      {/* Header Section */}
      <div
        className="relative lg:h-[52rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end"
        id="header"
      >
        <img
          src={asset1}
          alt="header"
          className="w-full h-full object-fill md:object-center"
        />
      </div>

      {/* Main Section */}
      <div className="flex flex-col md:flex-row bg-darkgren overflow-hidden">
        <div className="md:w-[70%] w-full">
          <h1 className="bg-darkgren lg:text-5xl md:text-4xl text-3xl text-white text-center p-10">
            Financial Reports
          </h1>
          <p className="bg-darkgren text-xl font-sans mx-6 text-white text-center mb-10">
          The Constantine Foundation's financial report provides a comprehensive overview of 
          our fiscal performance and resource allocation for the reporting period. This report 
          highlights our commitment to transparency, accountability, and the effective use of 
          funds to achieve our mission of supporting impactful initiatives. Through prudent 
          financial management and donor support, we continue to drive meaningful change while 
          ensuring the sustainability of our programs.
          </p>
          <ProjectReport />
        </div>
        {/* Donor Section */}
        <div className="md:w-[30%] w-full mt-10 mr-2">
          {/* <Doner /> */}
          <ProjectCarousel />
        </div>
      </div>
    </div>
  );
}
