export default function MessageBox(props) {
  return (
    <div
      className={`border-l-4 mt-16 ${
        props.variant === "success"
          ? "border-green-500 bg-green"
          : props.variant === "danger"
          ? "border-red-500 bg-red-100"
          : props.variant === "warning"
          ? "border-yellow-500 bg-yellow-100"
          : "border-blue-500 bg-blue-100"
      } p-4`}
    >
      {props.children}
    </div>
  );
}
