import React, { useState } from "react";
import DontationBacground from "../assets/Constantine_Donate_rm.png";
import DontationB from "../assets/donate-button.png";
import { useNavigate } from "react-router-dom";

const Donation = () => {

  const navigate = useNavigate();
  return (
<div className="relative flex flex-col lg:flex-row w-full h-full overflow-hidden">

    {/* Image */}
    <div className="lg:p-14 md:p-12 p-0">
      <h1 className=" text-5xl text-greenshade text-center pb-5">Get In Touch With us</h1>
      <p className=" text-darkgren text-lg p-10 font-sans font-bold text-justify">Thank you for reaching out! Whether you have general inquiries, seek potential partnerships, are interested in volunteering, 
        or aspire to become an ambassador, we welcome your communication. Our team is dedicated to providing prompt and informative 
        responses. Please complete the form below with your details, and we'll make sure to get back to you as soon as possible.</p>
    </div>
    <div className="flex flex-col w-[50%] h-[50%] ml-28 md:ml-48 lg:ml-1 pr-10 pt-3">
    <img
    src={DontationBacground}
    alt="donation"
    className="w-[100%] h-fit pt-3"
  />
      {/* <img
    src={DontationB}
    alt="donation"
    className="w-[90%] hover:w-[100%] h-fit pt-3 shadow-black shadow-2xl rounded-full mt-3"
    onClick={
      () => {
        navigate("/donate");
        window.scrollTo(0, 0);
      }
    }
  /> */}
    <button
        onClick={
          () => {
            navigate("/donate");
            window.scrollTo(0, 0);
          }
        } 
        type="Submit" 
        className="p-4 hover:p-6 text-3xl border-greenshade border-2 w-[70%] ml-10 mt-4 bg-white text-primary hover:bg-primary hover:text-white rounded-full">
        Donate
    </button>
    </div>
</div>
    
  );
};

export default Donation;
