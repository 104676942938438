import * as React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import Modal from "./VolunteerPopup";
import Volunteer from './volunteer';
import Career from './Career';
import header from "../../src/assets/join our team.png"
import CareerData from "./CareerPage";

const Careers = () => {
  // const [showFullText1, setShowFullText1] = useState(false);
  // const [showFullText2, setShowFullText2] = useState(false);
  // const [showFullText3, setShowFullText3] = useState(false);
  // const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null); // To track which component to show

  const headerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, delay: 0.3 } },
  };

  const buttonVariants = {
    hover: { scale: 1.1, transition: { yoyo: Infinity } },
  };

  const cardVariants = {
    rest: { scale: 1, boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)" },
    hover: { scale: 1.05, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", transition: { duration: 0.3 } },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

    const sectionVariantss = {
      hidden: { opacity: 0, y: 30 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.8, staggerChildren: 0.2 } },
    };
  
    const itemVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    };

  return (
    <>
      {/* Hero Section */}
      <motion.div
        id="header"
        className="relative w-full h-[50rem] flex items-center justify-center bg-gradient-to-r from-darkgren to-greenlight text-white"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        <img src={header} alt="gif" className="absolute h-full w-full object-cover" />
      </motion.div>

      {/* Mission Section */}
      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
          Be a Catalyst for Change: Shape Africa's Future with Constantine African Foundation.
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Start up, Journey with Constantine African Foundation: Lighting the Way with Limited Resources, Amplifying Impact with Your Value!
            As a non-profit organization, Constantine African Foundation is committed to illuminating the continent with the light of 
            Christ and empowering communities to flourish. While we navigate this journey with limited financial resources, we firmly believe 
            in the transformative power of your unique contributions. Join us as we embark on an extraordinary voyage to make a profound impact 
            on the lives of countless individuals across Africa.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Our Guiding Beacon: Illuminating the Path to Prosperity
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
            Founded with a vision rooted in Christian values, the Constantine African Foundation seeks to initiate projects that foster 
            prosperity and opportunity across Africa. Despite being in the early stages of our journey, our unwavering determination 
            propels us forward, fueled by an unwavering commitment to making a meaningful difference.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Sailing Beyond Financial Constraints: Your Value Amplifies Our Impact
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        While our budget may be modest, we are far from being alone. Your unwavering support and dedication empower us to surmount 
            financial challenges and accomplish remarkable feats. With your invaluable contributions, we can amplify our impact and 
            transform lives across the continent.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        A Mission Without Borders: Extending Our Reach to Two African Nations
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Our mission transcends borders, extending its reach to two distinct African nations. Our team of five dedicated missionaries 
            is already making a tangible difference on the ground, tirelessly working to uplift communities and foster hope. Additionally, 
            we allocate an annual grant to further our mission, ensuring that our impact is felt far and wide.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Career Opportunities: Transforming Individuals and Communities
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Constantine African Foundation offers career opportunities that are not just professionally fulfilling but also transformative 
            for individuals and communities across Africa. Join us as we embark on this inspiring voyage, guided by the Lord's name and 
            divine blessings. Together, with God's grace, we shall forge a brighter future for all.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Together, We Can Make a Lasting Impact
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Your contributions, no matter how big or small, have the power to fuel our journey and achieve extraordinary outcomes. We invite 
            you to be a part of this transformative odyssey, spreading the light and love of Christ to all corners of Africa. Join us as we 
            embark on this inspiring voyage, making a lasting impact that transcends borders and reshapes lives.
            With your unwavering support, we can illuminate the path to prosperity and empower countless individuals across Africa 
            to reach their full potential. 
        </p>
      </motion.div>

      <div className="mx-auto max-w-4xl my-16 px-6">
      {/* First Section */}
      <motion.div
        className="text-center bg-gradient-to-r from-darkgreen to-greenlight rounded-lg p-8 shadow-lg"
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <h2 className="text-3xl md:text-5xl font-bold text-white mb-4">
          "Together, we can make a world of difference."
        </h2>
      </motion.div>

        {/* Apply Now Button */}
        <div className="flex justify-center mt-10">
          <motion.button
            className="rounded-xl bg-darkgreen text-2xl px-5 py-2 mt-5 text-white cursor-pointer hover:bg-green-600 shadow-lg"
            onClick={() => setModalContent(<Career />)} // Set Career component as content
            whileHover="hover"
            variants={buttonVariants}
          >
            Apply Now!
          </motion.button>
        </div>


      <hr className="my-16 border-gray-300" />

      {/* Second Section */}
      <motion.div
        className="text-center p-8 text-darkgren rounded-lg shadow-lg"
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <h1 className="text-4xl md:text-5xl font-semibold text-gray-800 mb-4">
          Preparing for a Career with the Constantine African Foundation:
        </h1>
        <h2 className="text-3xl md:text-4xl font-medium text-blue-600 mb-8">
          Is It Your Next Step?
        </h2>
        <p className="text-lg md:text-xl text-gray-700 leading-relaxed font-sans text-justify">
          Constantine African Foundation presents an attractive salary package
          paired with outstanding benefits. Please note that opportunities in
          Ghana, Ivory Coast, Zambia and Uganda are exclusively available to individuals with Ghana, Ivory Coast, Zambia and Ugandan
          citizenship.
          <br />
          <br />
          If you are interested in submitting your resume and a letter of
          interest or cover letter, have any additional questions, or would
          like to explore other career opportunities with the Constantine
          African Foundation, please don't hesitate to reach out to us at:{" "}
          <a
            href="mailto:career@constantinefoundation.org"
            className="text-blue-500 hover:underline"
          >
            career@constantinefoundation.org
          </a>
          . We would be delighted to have a conversation with you.
        </p>
      </motion.div>
    </div>

    <motion.div
      className="mx-auto max-w-4xl p-8 bg-gray-50 text-darkgren rounded-lg shadow-lg my-16"
      initial="hidden"
      animate="visible"
      variants={sectionVariantss}
    >
      {/* Mission Paragraph */}
      <motion.p
        className="text-lg text-gray-700 leading-relaxed mb-6 font-sans text-justify" 
        variants={itemVariants}
      >
        If you feel a deep calling to leverage your talents and skills across Africa to bring people closer to Christ and His Church, consider embarking on a mission journey. Through immersive workshops, you can explore vocations like Byzantine drawing, Byzantine marble carving, and Byzantine chanting.
        <br />
        Discover the array of missionary opportunities waiting for you as we collaborate with our international mission partners. Take a step towards your mission{" "}
        <a className="hover:underline text-blue-500 font-semibold" href="#Ghana, Ivory Coast, Zambia and Uganda">
          click here
        </a>{" "}
        to explore the available options.
      </motion.p>

      {/* Headings */}
      <motion.h1
        className="text-3xl text-center font-bold text-gray-800 mb-4"
        variants={itemVariants}
      >
        Unlock Your Potential,
      </motion.h1>
      <motion.h2
        className="text-2xl text-center font-semibold text-blue-600 mb-6"
        variants={itemVariants}
      >
        Join Us in a Mission of Service, Sharing, and Inspiration.
      </motion.h2>

      {/* Additional Paragraphs */}
      <motion.p className="text-lg text-gray-700 leading-relaxed mb-4 font-sans text-justify" variants={itemVariants}>
        Are You Ready to Answer God's Divine Calling: 'Go Forth and Spread the Good News Worldwide.'
      </motion.p>
      <motion.p className="text-lg text-gray-700 leading-relaxed mb-4 font-sans text-justify" variants={itemVariants}>
        The Constantine African Foundation invites you to step into a life-changing experience where your skills, passions, and unwavering dedication can make a lasting impact.
      </motion.p>
      <motion.p className="text-lg text-gray-700 leading-relaxed mb-4 font-sans text-justify" variants={itemVariants}>
        Whether you're a seminarian, teacher, priest, professional, skilled tradesperson, engineer, or retiree, there is a place for you to share with us.
      </motion.p>
      <motion.p className="text-lg font-sans text-justify text-gray-700 leading-relaxed mb-4" variants={itemVariants}>
        Become a Constantine African Foundation volunteer missionary to help spread the Light and hope by teaching and delivering your experiences, practicing proclaiming the workshop, and sharing salvific messages throughout Africa.
      </motion.p>
      <motion.p className="text-lg font-sans text-justify text-gray-700 leading-relaxed mb-4" variants={itemVariants}>
        Be a part of all missionary job opportunities that have been approved and received the blessing of local hierarchs or the Metropolitan of the hosting country.
      </motion.p>
      <motion.p className="text-lg text-gray-700 font-sans text-justify leading-relaxed" variants={itemVariants}>
        Discover the available prospects for fostering unity among individuals within the Constantine African Foundation.
      </motion.p>
    </motion.div>


        {/* Be Volunteer Button */}
        <div className="flex justify-center mt-10">
          <motion.button
            className="rounded-xl bg-darkgreen text-2xl px-5 py-2 mt-5 text-white cursor-pointer hover:bg-green-600 shadow-lg"
            onClick={() => setModalContent(<Volunteer />)} // Set Volunteer component as content
            whileHover="hover"
            variants={buttonVariants}
          >
            Be Volunteer
          </motion.button>
        </div>

        {/* Modal */}
        <Modal open={modalContent !== null} onClose={() => setModalContent(null)}>
          <div className="text-center w-96">
            {modalContent} {/* Render the selected component */}
            <button
              className="bg-darkgreen hover:bg-greendark text-white font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded mt-4"
              onClick={() => setModalContent(null)}
            >
              Close
            </button>
          </div>
        </Modal>

{/* Cards Section */}
        <CareerData />

    </>
  );
};

export default Careers;