// Donation Component
import React, { useEffect, useState } from "react";
import back from "../../assets/Donation section/SVG/home page donation .png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import image10 from "../../assets/10 usd.mp4";
import image30 from "../../assets/30.mp4";
import image50 from "../../assets/50 usd.mp4";
import image75 from "../../assets/75 usd.mp4";
import image100 from "../../assets/100 usd.mp4";
import donateImage from "../../assets/donate now.gif.mp4";

const cardVariants = {
  offscreen: { y: 200 },
  onscreen: {
    y: 0,
    transition: { type: "spring", bounce: 0.4, duration: 0.8 },
  },
};

const Donation = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [curency, setCurency] = useState("USD"); // Initial state is set to "USD"
  const [number, setNumber] = useState(0);

  const handleCurencyChange = (e) => {
    setCurency(e.target.value); // Update state with the selected value
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
        const response = await axios.get(url);
        const projects = response.data.data.map((project) => ({
          id: project.id,
          name: project.attributes.name,
        }));
        setProjectOptions(projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="flex flex-wrap w-full" id="donation">
      <div className="flex w-full">
        <img
          className="lg:absolute lg:h-full lg:left-[40%] md:absolute md:h-[50%] md:w-[60%] md:left-[30%] absolute h-[85%] left-[0%] lg:pt-0 md:pt-0 pt-32"
          alt="background"
          src={back}
        />
        <div className="w-11/12 m-auto md:mb-32 mb-10 flex flex-col gap-1 items-left text-center py-10 relative">
          <div className="flex mt-10 z-10 flex-col relative text-center">
            <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]" />
            <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade w-[100%] md:w-[50%] ml-[-3%] sm:l-[25%]">
              Make Donation
            </p>
            <motion.p
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={cardVariants}
              className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-2 text-green md:mb-5 capitalize w-[100%] md:w-[50%] ml-[-5%]"
            >
              Be a part of what we achieved so far
            </motion.p>
          </div>
          <div className="flex w-full gap-10 relative py-[5rem]">
            <motion.div
              initial={{ x: -400, opacity: 0 }}
              viewport={{ once: true }}
              whileInView={{
                x: 0,
                scale: 1,
                opacity: 1,
                transition: { duration: 0.8, bounce: 1, type: "easy" },
              }}
              className="lg:h-[30rem] lg:w-[38rem] md:h-[25rem] md:w-[25rem] h-[32rem] w-[20rem] bg-green rounded-3xl lg:p-10 md:p-5 p-3 text-left text-white lg:pt-0 md:pt-0 pt-32"
            >
              <p className="font-roboto font-bold md:text-[1.3rem] text-[1.1rem] my-5 md:mb-10">
                PLACE YOUR DONATION TODAY
              </p>
              <div className="flex flex-row gap-4">
              <input
                type="text"
                value={"$" + number}
                readOnly
                className="md:w-9/12 w-full mb-6 border-2 font-sans text-2xl bg-green text-white border-white rounded-xl p-3"
              />
                <select
                  id="currency"
                  value={curency} // Bind state to the select element
                  onChange={handleCurencyChange} // Update state on change
                  className="md:w-3/12 w-full mb-6 border-2 font-sans text-2xl bg-green text-white border-white rounded-xl p-3"
                >
                  <option value="USD">USD &#36;</option>
                  <option value="EUR">EUR &#8364;</option>
                  {/* <option value="Cedi">Cedi &#8373;</option> */}
                </select>
                {/* <p className="text-white mt-4">
                  Selected Currency: <span className="font-bold">{curency}</span>
                </p> */}
              </div>
              <select
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                className="md:w-9/12 w-full mb-6 border-2 font-sans text-2xl bg-green text-white border-white rounded-xl p-3"
              >
                <option value="" disabled>
                  Please select the purpose of your donation
                </option>
                {projectOptions.map((project) => (
                  <option key={project.id} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </select>
              <div className="md:w-12/12 w-12/12 mb-6 text-greendark text-center flex gap-2">
                {[image10, image30, image50, image75, image100].map((src, index) => {
                  const number = parseInt(src.match(/\d+/)[0]); // Extract the number from the variable name
                  return (
                    <div
                      key={index}
                      onClick={() => setNumber(number)}
                      className="rounded-xl p-0 w-2/12"
                    >
                      <video
                        autoPlay
                        muted
                        loop
                        className="object-contain self-stretch w-[100%] h-[100%] mx-auto rounded-full"
                      >
                        <source src={src} type="video/mp4" />
                      </video>
                    </div>
                  );
                })}
              </div>
              <div className="md:w-6/12 w-9/12 mb-6 text-center rounded-xl p-0">
              <Link
                to="/donate"
                state={{ selectedProject, number, curency }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                // className="md:w-6/12 w-9/12 mb-6 text-center rounded-xl p-0"
              >
                <video
                  autoPlay
                  muted
                  loop
                  className="object-contain self-stretch w-[80%] h-[100%] mx-auto mt-0 rounded-full"
                >
                  <source src={donateImage} type="video/mp4" />
                </video>
              </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="w-3/4 m-auto h-1 bg-black"></div>
    </div>
  );
};

export default Donation;