import React, { useState } from 'react';
import axios from 'axios';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cv: null // For the file upload
  });

  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      cv: e.target.files[0] // Save the uploaded file
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Step 1: Upload the CV file
      const filePayload = new FormData();
      filePayload.append('files', formData.cv);
      
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        filePayload,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          }
        }
      );
  
      if (uploadResponse.status !== 200 && uploadResponse.status !== 201) {
        throw new Error('File upload failed');
      }
  
      const uploadedFileId = uploadResponse.data[0].id;
  
      // Step 2: Submit the form data with the uploaded file ID
      const formPayload = {
        data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          cv: uploadedFileId, // Reference the uploaded file by ID
        },
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscribers`,
        formPayload,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          }
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        console.log('Form submitted successfully:', response.data);
        setFormStatus('success');
  
        // Clear form fields
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          cv: null
        });
        document.getElementById('cv').value = null;
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      console.error(error.response ? error.response.data : 'Error', error.message);
      setFormStatus('error');
    }
  };
  

  return (
    <div className="mx-auto my-4 w-96">
      {formStatus === 'success' && (
        <div className="mb-4 text-green font-semibold">
          Application submitted successfully!
        </div>
      )}
      {formStatus === 'error' && (
        <div className="mb-4 text-red-600 font-semibold">
          There was an error submitting the form. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label htmlFor="firstName" className="block text-darkgreen text-sm font-bold mb-2 text-left">First Name:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="text" 
          name="firstName" 
          id="firstName" 
          value={formData.firstName} 
          onChange={handleChange} 
          required
        /><br />
        
        <label htmlFor="lastName" className="block text-darkgreen text-sm font-bold mb-2 text-left">Last Name:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="text" 
          name="lastName" 
          id="lastName" 
          value={formData.lastName} 
          onChange={handleChange} 
          required
        /><br />
        
        <label htmlFor="email" className="block text-darkgreen text-sm font-bold mb-2 text-left">Email:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="email" 
          name="email" 
          id="email" 
          value={formData.email} 
          onChange={handleChange} 
          required
        /><br />
        
        <label htmlFor="cv" className="block text-darkgreen text-sm font-bold mb-2 text-left">Upload CV:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
          type="file" 
          name="cv" 
          id="cv" 
          onChange={handleFileChange} 
          accept=".pdf,.doc,.docx"
          required
        /><br /><br />
        
        <button 
          className="bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded" 
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ApplicationForm;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import emailjs from 'emailjs-com';

// const ApplicationForm = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     cv: null, // For the file upload
//   });

//   const [formStatus, setFormStatus] = useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       cv: e.target.files[0], // Save the uploaded file
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       // Prepare the form data for EmailJS
//       const emailPayload = {
//         from_name: `${formData.firstName} ${formData.lastName}`,
//         from_email: formData.email,
//         message: `New application from ${formData.firstName} ${formData.lastName}.`,
//       };

//       // Create a FormData object to handle the file attachment
//       const formDataObj = new FormData();
//       formDataObj.append('file', formData.cv);
//       formDataObj.append('service_id', process.env.REACT_APP_EMAILJS_SERVICE_ID);
//       formDataObj.append('template_id', process.env.REACT_APP_EMAILJS_TEMPLATE_ID);
//       formDataObj.append('user_id', process.env.REACT_APP_EMAILJS_USER_ID);
//       formDataObj.append('from_name', emailPayload.from_name);
//       formDataObj.append('from_email', emailPayload.from_email);
//       formDataObj.append('message', emailPayload.message);

//       // Send the email
//       await fetch('https://api.emailjs.com/api/v1.0/email/send-form', {
//         method: 'POST',
//         body: formDataObj,
//       });

//       setFormStatus('success');

//       // Clear form fields
//       setFormData({
//         firstName: '',
//         lastName: '',
//         email: '',
//         cv: null,
//       });
//       document.getElementById('cv').value = null;
//     } catch (error) {
//       console.error('Error sending email:', error);
//       setFormStatus('error');
//     }
//   };

//   return (
//     <div className="mx-auto my-4 w-96">
//       {formStatus === 'success' && (
//         <div className="mb-4 text-green font-semibold">
//           Application submitted successfully!
//         </div>
//       )}
//       {formStatus === 'error' && (
//         <div className="mb-4 text-red-600 font-semibold">
//           There was an error submitting the form. Please try again.
//         </div>
//       )}
//       <form onSubmit={handleSubmit}>
//         <label htmlFor="firstName" className="block text-darkgreen text-sm font-bold mb-2 text-left">First Name:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="text" 
//           name="firstName" 
//           id="firstName" 
//           value={formData.firstName} 
//           onChange={handleChange} 
//           required
//         /><br />
        
//         <label htmlFor="lastName" className="block text-darkgreen text-sm font-bold mb-2 text-left">Last Name:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="text" 
//           name="lastName" 
//           id="lastName" 
//           value={formData.lastName} 
//           onChange={handleChange} 
//           required
//         /><br />
        
//         <label htmlFor="email" className="block text-darkgreen text-sm font-bold mb-2 text-left">Email:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="email" 
//           name="email" 
//           id="email" 
//           value={formData.email} 
//           onChange={handleChange} 
//           required
//         /><br />
        
//         <label htmlFor="cv" className="block text-darkgreen text-sm font-bold mb-2 text-left">Upload CV:</label>
//         <input 
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgreen leading-tight focus:outline-none focus:shadow-outline" 
//           type="file" 
//           name="cv" 
//           id="cv" 
//           onChange={handleFileChange} 
//           accept=".pdf,.doc,.docx"
//           required
//         /><br /><br />
        
//         <button 
//           className="bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded" 
//           type="submit"
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// export default ApplicationForm;
