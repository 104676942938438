import header from "../assets/our Team  Header copy 2.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Model from "react-modal";
import { motion } from "framer-motion";
import two from "../assets/Designer.png";
import GeorgeBio from "../components/GeorgeBio"

const OurTeam = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + `/our-teams?populate=*`;

        if (!process.env.REACT_APP_API_URL) {
          throw new Error("API URL is not defined in the environment variables.");
        }

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`, // Add token if required
          },
        });

        setTeamMembers(response.data.data || []);
      } catch (error) {
        console.error("Error fetching team members:", error);
        // alert("Failed to fetch team members. Please check your API configuration.");
      }
    };

    fetchTeamMembers();
  }, []);

  const handleOpenModal = (member) => {
    setSelectedMember(member);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedMember(null);
    setModalOpen(false);
  };

  return (
    <>
      {/* Header Image */}
      <img
        src={header}
        alt="header"
        className="w-full object-cover object-right md:object-center"
      />



     <div className="bg-white flex flex-col items-stretch pl-11 pr-20 py-11 mb-16 max-md:px-5">
         <div className="mr-11 max-md:max-w-full max-md:mr-2.5">
            <div className="gap-5 flex max-md:flex-col px-0 mb-3 md:mb-[-9rem] lg:mb-[-9rem] md:px-[10rem] lg:px-[20rem] max-md:items-stretch max-md:gap-0">

             <div className="flex flex-col items-stretch w-[100%] ml-5 max-md:w-full max-md:ml-0">
                                   <motion.img
                          viewport={{ once: true }}
                          initial={{
                            x: 0,
                            opacity: 0,
                            scale: 0,
                          }}
                          whileInView={{
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            transition: {
                              duration: 0.5,
                              bounce: 0.1,
                              delay: 0.6,
                              type: "easy",
                            },
                          }}
                          src={two}
                          alt="logo"
                          className="rounded-3xl aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                        />
                     <div> 
                                <button onClick={() => setVisible(true)} className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">
                                    Read About | Sir George Constantine
                                </button>
                                <br />
                                <br />
                                <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isVisible}
                          onRequestClose={() => setVisible(false)}
                        >
                          <GeorgeBio closeModal={() => setVisible(false)} />
                        </Model>
                        </div>  
                     </div>
            </div>
            </div>
            </div>
            </div>




      <div className="bg-white flex flex-col items-stretch pl-11 pr-20 py-11 max-md:px-5">
        <div className="mr-11 max-md:max-w-full max-md:mr-2.5">
          <div className="gap-5 flex flex-wrap justify-center px-0 mb-3">
            {teamMembers.map((member, index) => (
              <div
                key={member.id}
                className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0"
              >
                {/* Dynamic Image */}
                <motion.img
                  viewport={{ once: true }}
                  initial={{ x: 0, opacity: 0, scale: 0 }}
                  whileInView={{
                    x: 0,
                    scale: 1,
                    opacity: 1,
                    transition: { duration: 0.5, bounce: 0.1, delay: 0.3 * index, type: "ease" },
                  }}
                  // g.attributes?.left_img_1?.data[0].attributes?.url
                  // g.attributes?.name
                  src={process.env.REACT_APP_API_IMAGE_URL + member.attributes?.image?.data[0].attributes?.url || ""}
                  alt={member.attributes?.fullName}
                  className="rounded-3xl aspect-square object-contain object-center w-full overflow-hidden max-md:mt-10"
                />

                {/* Dynamic Button */}
                <button
                  onClick={() => handleOpenModal(member)}
                  className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded mt-4"
                >
                  Read About | {member.attributes?.fullName}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal for Member Bio */}
      {selectedMember && (
        <Model
          style={{ zIndex: 50 }}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        >
          <div className="p-4">
            <h2 className="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">{selectedMember.attributes.fullName}</h2>
            <p className="font-sans">{selectedMember.attributes.bio}</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-darkgren text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </Model>
      )}
    </>
  );
};

export default OurTeam;

