import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { SampleNextArrow, SamplePrevArrow } from "../../components/SliderArow";
import boxp1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxp2 from "../../assets/Headers/SVG/Asset_3.svg";

const Project = () => {
  const [Data, setData] = useState(null);
  const [SlideToShow, setSlideToShow] = useState(
    window.matchMedia("(max-width: 639px)").matches ? 1 : 3
  );

  const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      setData(data?.data);
    };

    fetchData();
  }, [url]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: SlideToShow,
    slidesToScroll: 1,
    className: "campaings w-inherit",
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    // rows: 1,
  };

  return (
    <div className="flex mt-0 z-10 flex-col relative text-center">
      <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]">
        {/* First motion image */}
        <motion.img
          initial={{
            x: 50,
            y: 50,
            opacity: 0,
          }}
          whileInView={{
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.7,
              type: "easy",
            },
          }}
          className="absolute  -z-10 lg:bottom-[90%] lg:left-[12%] lg:h-[8%] md:bottom-[90%] md:left-[14%] md:h-[6%] bottom-[95%] left-[11.5%]  h-[4%]"
          alt="box"
          src={boxp2}
        />

        {/* Second motion image */}
        <motion.img
          initial={{
            x: -50,
            y: -50,
            opacity: 0,
          }}
          whileInView={{
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.7,
              type: "easy",
            },
          }}
          className="absolute  -z-10 lg:bottom-[86%] lg:left-[13%]  lg:h-[8%] md:bottom-[87%] md:left-[15%]  md:h-[6%] bottom-[93%] left-[13%]  h-[4%]"
          alt="box"
          src={boxp2}
        />

        {/* Third motion image */}
        <motion.img
          initial={{
            x: -50,
            y: 50,
            opacity: 0,
          }}
          whileInView={{
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.7,
              type: "easy",
            },
          }}
          className="absolute -z-10  lg:bottom-[84%] lg:left-[10.8%]  lg:h-[8%] md:bottom-[86%] md:left-[12.5%]  md:h-[6%] bottom-[92%] left-[9.3%]  h-[4%]"
          alt="box"
          src={boxp1}
        />
      </motion.div>

      <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade">Project</p>
      <motion.p
        initial={{
          y: 10,
          scale: 0.1,
        }}
        whileInView={{
          y: 0,
          scale: 1,
          transition: {
            duration: 0.8,
            bounce: 0.1,
            type: "easy",
          },
        }}
        viewport={{ once: true }}
        className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] text-green mb-5"
      >
        The Project that we are currently Running
      </motion.p>
      <div
        className="font-roboto w-full mt-5 md:mt-20"
        style={{
          textAlign: "-webkit-center",
        }}
      >
        <Slider {...settings}>
              {Data?.map((project) => (
                <div className="h-[28rem]" key={project.id}>
                  <motion.div
                    viewport={{ once: true }}
                    className="m-auto relative bg-white rounded-[1rem] md:h-[17rem] h-[9rem] md:mx-10 mx-1 md:hover:mx-7 hover:mx-2 md:w-[15rem] w-[11rem] md:hover:h-[20rem] hover:h-[11.5rem] md:hover:w-[18rem] hover:w-[12rem] group"
                  >
                    <Link to={`/projects/${Data[0].id}`} onClick={() => window.scrollTo(0, 0)}>
                    <img
                      alt="projectImage"
                      src={process.env.REACT_APP_API_IMAGE_URL + project.attributes?.left_img_1?.data[0].attributes?.url}
                      className="w-full h-full object-cover rounded-[3rem]"
                    />
                    </Link>
                    <div className="absolute top-[90%] left-[50%] -translate-x-[50%] w-11/12 m-auto p-3 bg-white rounded-[1rem] drop-shadow-[0_7px_7px_rgba(0,0,0,0.3)] text-left -translate-y-10">
                      <p className="pl-2 mb-2 text-green font-bold">
                      <Link to={`/projects/${Data[0].id}`} onClick={() => window.scrollTo(0, 0)}>
                        {project.attributes.name}
                        </Link>
                      </p>

                      <div className={`group-hover:block block sm:hidden overflow-hidden`}>
                        <div className="pl-2 leading-4 text-sm mb-3 text-orange max-h-[6rem] overflow-hidden line-clamp-3">
                        <Link to={`/projects/${Data[0].id}`} onClick={() => window.scrollTo(0, 0)}>
                          {project.attributes.left_desc_1}
                          </Link>
                        </div>
                        <div className="w-full flex justify-start text-white font-bold">
                          <Link to={`/projects/${Data[0].id}`} onClick={() => window.scrollTo(0, 0)}>
                            <div className="bg-buttongreen cursor-pointer font-sans rounded-3xl mb-4 px-2 py-1 text-sm ">
                              Read More
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="w-full m-auto h-2 bg-orange rounded-3xl">
                        <div className="w-2/5 h-2 bg-buttongreen rounded-l-3xl"></div>
                      </div> */}
                      <div className="w-full m-auto h-2 bg-orange rounded-3xl">
                        <div
                          className="h-2 bg-buttongreen rounded-l-3xl"
                          style={{
                            width: `${
                              (project.attributes?.budgets / project.attributes?.goal) * 100 || 0
                            }%`,
                          }} 
                        >
                        </div>
                        <p>
                           
                        </p>
                      </div>

                    </div>
                  </motion.div>
                </div>
              ))
          }
        </Slider>
      </div>
      <div className="gap- mt-20 font-sans"></div>
    </div>
  );
};

export default Project;
