// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import ShareButton from "../../components/ShareButton";
// import donateImage from '../../assets/donate now.gif.mp4';

// const DonorList = ({ project }) => {
//   const navigate = useNavigate();
//   const [data, setData] = useState(null);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const [projectBudget, setProjectBudget] = useState(0);
//   const [projectGoal, setProjectGoal] = useState(0);

//   const formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD'
//   });

//   const headers = {
//     "Authorization": "Bearer " + process.env.REACT_APP_API_TOKEN
//   };

//   useEffect(() => {
//     const fetchDonorsForProject = async () => {
//       try {
//         const encodedProject = encodeURIComponent(project);
//         const { data } = await axios({
//           method: "get",
//           url: `${process.env.REACT_APP_API_URL}/donors?filters[purpose][$eq]=${encodedProject}&populate=*`,
//           headers,
//         });
//         setData(data?.data);
//       } catch (error) {
//         console.error('Error fetching donor data for project:', error);
//       }
//     };

//     fetchDonorsForProject();
//   }, [project]);

//   useEffect(() => {
//     const fetchProjectBudget = async () => {
//       try {
//         const { data } = await axios({
//           method: "get",
//           url: `${process.env.REACT_APP_API_URL}/projects?filters[name][$eq]=${project}`,
//           headers,
//         });
//         const projectData = data?.data?.[0]?.attributes;
//         if (projectData) {
//           setProjectBudget(projectData.budget || 0);
//           setProjectGoal(projectData.goal || 0);
//         }
//       } catch (error) {
//         console.error('Error fetching project budget:', error);
//       }
//     };

//     fetchProjectBudget();
//   }, [project]);

//   useEffect(() => {
//     if (data) {
//       const total = data.reduce((acc, donor) => acc + donor.attributes.amount, 0);
//       setTotalAmount(total);
//     }
//   }, [data]);

//   const progressBarPercentage = projectBudget > 0
//     ? Math.min((totalAmount / projectBudget) * 100, 100)
//     : 0;

//   useEffect(() => {
//     console.log('Total Amount Raised:', totalAmount);
//     console.log('Project Budget:', projectBudget);
//     console.log('Progress Bar Percentage:', progressBarPercentage);
//   }, [totalAmount, projectBudget]);

//   const circumference = 2 * Math.PI * 45; // Calculate dynamically based on radius
//   const strokeDashoffset = circumference - (circumference * progressBarPercentage) / 100;
//   const numberOfDonors = data?.length || 0;

//   return (
//     <div className="w-full max-w-[350px] h-auto p-[10px] bg-white border-white border-2 shadow-lg rounded-lg sticky mb-4">
//       <div className=' text-center text-2xl font-black text-darkgren'>
//         <h1>{project}</h1>
//       </div>
//       <div className="flex flex-row">
//         <div className="mt-8 mr-4">
//           <h1 className="text-lg text-darkgreen font-sans font-black">
//             {formatter.format(totalAmount)} raised
//           </h1>
//           <div className="flex flex-row">
//             <h1 className="text-gray px-1 font-sans">
//               {formatter.format(projectGoal)} goal
//             </h1>
//             &bull;
//             <p className="text-gray px-1 font-sans">{numberOfDonors} donations</p>
//           </div>
//         </div>
//         <div className="my-3 flex justify-center">
//           <div className="relative">
//             <svg className="w-[100px] h-[100px]">
//               <circle
//                 className="text-gray"
//                 strokeWidth="8"
//                 stroke="currentColor"
//                 fill="transparent"
//                 r="45"
//                 cx="50"
//                 cy="50"
//               />
//               <circle
//                 className="text-green"
//                 strokeWidth="8"
//                 strokeDasharray={circumference}
//                 strokeDashoffset={strokeDashoffset}
//                 strokeLinecap="round"
//                 stroke="currentColor"
//                 fill="transparent"
//                 r="45"
//                 cx="50"
//                 cy="50"
//                 style={{ transition: 'stroke-dashoffset 0.5s ease' }}
//               />
//             </svg>
//             <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
//               <span className="text-lg font-bold text-darkgreen">
//                 {Math.round(progressBarPercentage)}%
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="flex flex-col">
//         <ShareButton />

//                         <video
//                         autoPlay
//                         muted
//                         loop
//                         className="object-contain self-stretch w-[80%] h-[100%] mx-auto mt-2 rounded-full"
//                         onClick={() => {
//                           navigate("/donate", {
//                             state: { project },
//                           });
//                           window.scrollTo(0, 0);
//                         }}
//                         >
//                         <source src={donateImage} type="video/mp4" />
//                         Your browser does not support the video tag.
//                         </video>
//       </div>

//       <div className="pt-3 pb-[3%]">
//         <h2 className="text-greenshade text-[1.5rem] font-sans">List of Donors</h2>
//       </div>
//       <div className="pb-[20px] flex gap-1 h-[19rem] overflow-y-auto">
//         <div className="w-full cursor-pointer">
//           {data?.map((x) => (
//             <div key={x.id} className="text-greenshade">
//               <h5 className="font-sans text-lg">
//                 {x.attributes?.firstName} {x.attributes?.lastName} {formatter.format(x.attributes?.amount)}
//               </h5>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DonorList;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ShareButton from "../../components/ShareButton";
import donateImage from '../../assets/donate now.gif.mp4';

const DonorList = ({ project }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [projectBudget, setProjectBudget] = useState(0);
  const [projectGoal, setProjectGoal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const headers = {
    "Authorization": "Bearer " + process.env.REACT_APP_API_TOKEN
  };

  useEffect(() => {
    const fetchDonorsForProject = async () => {
      try {
        const encodedProject = encodeURIComponent(project);
        const { data } = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/donors?filters[purpose][$eq]=${encodedProject}&populate=*`,
          headers,
        });
        setData(data?.data);
      } catch (error) {
        console.error('Error fetching donor data for project:', error);
      }
    };

    fetchDonorsForProject();
  }, [project]);

  useEffect(() => {
    const fetchProjectBudget = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/projects?filters[name][$eq]=${project}`,
          headers,
        });
        const projectData = data?.data?.[0]?.attributes;
        if (projectData) {
          setProjectBudget(projectData.budget || 0);
          setProjectGoal(projectData.goal || 0);
        }
      } catch (error) {
        console.error('Error fetching project budget:', error);
      }
    };

    fetchProjectBudget();
  }, [project]);

  useEffect(() => {
    if (data) {
      const total = data.reduce((acc, donor) => acc + donor.attributes.amount, 0);
      setTotalAmount(total);
    }
  }, [data]);

  const progressBarPercentage = projectBudget > 0
    ? Math.min((totalAmount / projectBudget) * 100, 100)
    : 0;

  const openModal = (type) => {
    if (type === "all") {
      setModalTitle("All Donors");
      setModalContent(data || []);
    } else if (type === "top") {
      setModalTitle("Top 5 Donors");
      const topDonors = [...(data || [])].sort((a, b) => b.attributes.amount - a.attributes.amount).slice(0, 5);
      setModalContent(topDonors);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent([]);
    setModalTitle("");
  };

  const circumference = 2 * Math.PI * 45;
  const strokeDashoffset = circumference - (circumference * progressBarPercentage) / 100;
  const numberOfDonors = data?.length || 0;

  return (
    <div className="w-full max-w-[350px] h-auto p-[10px] bg-white border-white border-2 shadow-lg rounded-lg sticky mb-4">
      <div className=' text-center text-2xl font-black text-darkgren'>
        <h1>{project}</h1>
      </div>
      <div className="flex flex-row">
        <div className="mt-8 mr-4">
          <h1 className="text-lg text-darkgreen font-sans font-black">
            {formatter.format(totalAmount)} raised
          </h1>
          <div className="flex flex-row">
            <h1 className="text-gray px-1 font-sans">
              {formatter.format(projectGoal)} goal
            </h1>
            &bull;
            <p className="text-gray px-1 font-sans">{numberOfDonors} donations</p>
          </div>
        </div>
        <div className="my-3 flex justify-center">
          <div className="relative">
            <svg className="w-[100px] h-[100px]">
              <circle
                className="text-gray"
                strokeWidth="8"
                stroke="currentColor"
                fill="transparent"
                r="45"
                cx="50"
                cy="50"
              />
              <circle
                className="text-green"
                strokeWidth="8"
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="45"
                cx="50"
                cy="50"
                style={{ transition: 'stroke-dashoffset 0.5s ease' }}
              />
            </svg>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <span className="text-lg font-bold text-darkgreen">
                {Math.round(progressBarPercentage)}%
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <ShareButton />

        <video
          autoPlay
          muted
          loop
          className="object-contain self-stretch w-[80%] h-[100%] mx-auto mt-2 rounded-full"
          onClick={() => {
            navigate("/donate", {
              state: { project },
            });
            window.scrollTo(0, 0);
          }}
        >
          <source src={donateImage} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="pt-3 pb-[3%]">
        <h2 className="text-greenshade text-[1.5rem] font-sans">List of Donors</h2>
      </div>
      <div className="pb-[20px] flex gap-1 h-[19rem] overflow-y-auto">
        <div className="w-full cursor-pointer">
          {data?.map((x) => (
            <div key={x.id} className="text-greenshade">
              <h5 className="font-sans text-lg">
                {x.attributes?.firstName} {x.attributes?.lastName} {formatter.format(x.attributes?.amount)}
              </h5>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={() => openModal("all")}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          See All
        </button>
        <button
          onClick={() => openModal("top")}
          className="bg-green-500 text-white py-2 px-4 rounded"
        >
          See Top
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] max-w-[500px]">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h2 className="text-xl font-bold">{modalTitle}</h2>
              <button onClick={closeModal} className="text-red-500 text-lg">&times;</button>
            </div>
            <div className="overflow-y-auto max-h-[300px]">
              {modalContent.map((donor, index) => (
                <div key={donor.id || index} className="border-b py-2">
                  <p>{donor.attributes?.firstName} {donor.attributes?.lastName}: {formatter.format(donor.attributes?.amount)}</p>
                </div>
              ))}
            </div>
            <div className="mt-4 text-right">
              <button onClick={closeModal} className="bg-gray-300 text-black py-2 px-4 rounded">Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonorList;
