import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Login = ({ setIsLogin }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const products = location.state?.products || [];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = formData;
    
        if (email && password) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`, {
                    params: { email, password },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    },
                });
    
                const data = response.data;
                console.log(data);
    
                // Assuming 'products' is defined somewhere and available
                console.log(products);
    
                if (data) {
                    setMessage('Success: Login successfully!');
                    // Navigate with both user data and products
                    navigate("/customer-profile", { state: { user: data, products } });
                } else {
                    setMessage('No account found. Please create a new account.');
                    setIsLogin(false);
                }
            } catch (error) {
                setMessage(error.response ? `Error: ${error.response.data.message}` : 'Error: No response from server');
            }
        } else {
            setMessage('Error: Please fill out all fields');
        }
    };    

    return (
        <div className="bg-white rounded-2xl shadow-2xl flex flex-col w-full md:w-[40%] items-center max-w-4xl transition duration-1000 ease-out">
             <h2 className="p-3 text-3xl font-bold text-darkgreen">Constantine Foundation</h2>
             <div className="inline-block border-[1px] justify-center w-20 border-darkgreen border-solid"></div>
             <h3 className="text-xl font-semibold text-darkgreen pt-2">Sign In!</h3>
             <div className="flex space-x-2 m-4 items-center justify-center">
                 <div className="socialIcon"></div>
             </div>
             <form className="flex flex-col items-center justify-center w-[80%]" onSubmit={handleSubmit}>
                 <input
                    type="email"
                    name="email"
                    className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-darkgreen focus:outline-none focus:ring-0 text-darkgreen"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    name="password"
                    className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 text-darkgreen"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <button
                    type="submit"
                    className="rounded-2xl m-2 text-white bg-darkgreen w-2/5 px-4 py-2 shadow-md hover:text-darkgreen hover:bg-white transition duration-200 ease-in"
                >
                    Sign In
                </button>
            </form>
            {/* Existing form elements */}
            {message && <p className={`mt-4 text-sm font-medium ${message.startsWith('Error') ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
        </div>
    );
};

Login.propTypes = {
    setIsLogin: PropTypes.func.isRequired,
};

export default Login;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { signInWithPopup, signInWithRedirect } from "firebase/auth";
// import { auth, googleProvider, facebookProvider } from "./firebase";
// import LoginForm from "./Login";
// import SignUpForm from "./Signup";
// import Logo from "../assets/Logo/ConstantionLogoColor.png";

// const Auth = () => {
//     const [isLogin, setIsLogin] = useState(true);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState("");
//     const navigate = useNavigate(); // Initialize useNavigate

//     // Unified Sign-In Function
//     const handleSignIn = async (provider) => {
//         setLoading(true);
//         setError("");
//         try {
//             const result = await signInWithPopup(auth, provider);
//             console.log("Sign-In Success:", result.user);
//             // Redirect to customer profile after successful login
//             navigate("/customer-profile");
//         } catch (error) {
//             if (error.code === "auth/popup-blocked") {
//                 console.warn("Popup blocked. Using redirect instead.");
//                 try {
//                     await signInWithRedirect(auth, provider);
//                 } catch (redirectError) {
//                     console.error("Redirect Sign-In Error:", redirectError.message);
//                     setError(`Redirect failed: ${redirectError.message}`);
//                 }
//             } else {
//                 console.error("Sign-In Error:", error.message);
//                 setError(error.message);
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="bg-gradient-to-tr from-darkgreen to-greenlight flex flex-col items-center justify-center min-h-screen md:py-2">
//             <main className="flex items-center w-full px-2 md:px-20">
//                 {/* Left Side - Logo */}
//                 <div className="hidden md:inline-flex flex-col flex-1 space-y-1">
//                     <img alt="waving tree" src={Logo} className="w-[70%]" />
//                 </div>

//                 {/* Right Side - Form */}
//                 <div className="flex flex-col space-y-4 w-full max-w-2xl">
//                     {isLogin ? (
//                         <LoginForm setIsLogin={setIsLogin} />
//                     ) : (
//                         <SignUpForm setIsLogin={setIsLogin} />
//                     )}

//                     {/* Error Display */}
//                     {error && (
//                         <div className="text-red-500 text-center">{error}</div>
//                     )}

//                     {/* Social Sign-In Buttons */}
//                     <div className="flex flex-col items-center space-y-2">
//                         <button
//                             onClick={() => handleSignIn(googleProvider)}
//                             className="bg-white text-black border rounded-lg px-4 py-2 w-full flex items-center justify-center"
//                             disabled={loading}
//                         >
//                             <img
//                                 src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
//                                 alt="Google Icon"
//                                 className="w-5 h-5 mr-2"
//                             />
//                             {loading ? "Signing in..." : "Sign in with Google"}
//                         </button>
//                         <button
//                             onClick={() => handleSignIn(facebookProvider)}
//                             className="bg-blue-600 text-white border rounded-lg px-4 py-2 w-full flex items-center justify-center"
//                             disabled={loading}
//                         >
//                             <img
//                                 src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
//                                 alt="Facebook Icon"
//                                 className="w-5 h-5 mr-2"
//                             />
//                             {loading ? "Signing in..." : "Sign in with Facebook"}
//                         </button>
//                     </div>
//                 </div>
//             </main>
//         </div>
//     );
// };

// export default Auth;
