import React, { useState, useEffect } from "react";
import FooterLogo from "../assets/FooterElements/SVG/Untitled-1.png";
import { Link } from "react-router-dom";
import Subscription from "../components/Subscription";
import Volunteer from "./volunteer";
import { FaTwitter, FaFacebook, FaLinkedin, FaYoutube, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Modal from "./VolunteerPopup";


const Footer = () => {
  const [open, setOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const currentUrl = window.location.href;

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

    const [language, setLanguage] = useState("en");
  
    const handleLanguageChange = (langCode) => {
      setLanguage(langCode);
      const translateUrl = `https://translate.google.com/translate?hl=${langCode}&sl=auto&tl=${langCode}&u=${window.location.href}`;
      window.location.href = translateUrl;
    };
    // useEffect(() => {
    //   const addGoogleTranslateScript = () => {
    //     const script = document.createElement("script");
    //     script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    //     document.body.appendChild(script);
  
    //     window.googleTranslateElementInit = () => {
    //       new window.google.translate.TranslateElement(
    //         {
    //           pageLanguage: "en",
    //           includedLanguages: "en,ar,fr,el",
    //           layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //         },
    //         "google_translate_element"
    //       );
    //     };
    //   };
  
    //   addGoogleTranslateScript();
    // }, []);

  return (
    <div className="w-full font-roboto text-white relative bg-gradient-to-b from-[#0b752a] to-[#00433b]">
      {/* Main footer content */}
      <div className="flex flex-wrap md:flex-nowrap p-6 md:p-10 lg:p-16 gap-6 md:gap-5">
        {/* Left section: Logo and description */}
        <div className="w-full">
          <img
            alt="logo"
            src={FooterLogo}
            className="lg:h-[7rem] md:h-[5rem] h-[4rem] object-cover mb-7 mx-auto md:mx-0"
          />
          <p className="text-[0.8rem] text-justify">
            Constantine African Foundation was established with a goal to advocate for the rights of marginalized and vulnerable populations
            to access quality education and healthcare in Ghana,Ivory Coast, Zambia, Uganda and all of Africa.
          </p>
        </div>

        {/* Middle section: Navigation links */}
        <div className="">
          <div className="flex flex-col md:flex-col lg:flex-row w-[10rem] md:w-[10rem] lg:w-[28rem] ml-28 md:ml-0 lg:ml-0 gap-2">
            <div className="w-full mb-6 md:mb-0">
              <p className="mb-4 text-[1.2rem] font-bold text-center md:text-left">How We Serve</p>
              <div className="grid grid-rows-2 gap-4 text-center md:text-left">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/campaings`}>
                  <p>Campaign</p>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to={`/Projects`}>
                  <p>Projects</p>
                </Link>
              </div>
            </div>

            <div className="w-full mb-6 md:mb-0">
              <p className="mb-4 text-[1.2rem] font-bold text-center md:text-left">Resources</p>
              <div className="grid grid-rows-2 gap-4 text-center md:text-left">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/Blogs`}>
                  <p>Blogs</p>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to={`/report`}>
                  <p>Reports</p>
                </Link>
              </div>
            </div>  

            <div className="w-full mb-6 md:mb-0">
              <p className="mb-4 text-[1.2rem] font-bold text-center md:text-left">Get to Know Us</p>
              <div className="grid grid-rows-2 gap-4 text-center md:text-left">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/our-team`}>
                  <p>Our Team</p>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to={`/about`}>
                  <p>About Us</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Right section: Call to action */}
        <div className="w-full md:w-[30rem] lg:w-[30rem]">
          <p className="mb-4 text-[1.2rem] font-bold text-center md:text-left">Help Our Cause</p>
          <div className="grid grid-rows-3 gap-4 text-center md:text-left">
            <Link onClick={() => window.scrollTo(0, 0)} to={`/Products`}>
              <p>Products</p>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to={`/Careers`}>
              <p>Careers</p>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to={`/Service`}>
              <p>Other Service</p>
            </Link>
          </div>
          <div className="mt-6 text-center md:text-left">
            <Modal open={open} onClose={() => setOpen(false)}>
              <div className="text-center w-96">
                <Volunteer />
                <div className="flex gap-4 mt-4">
                  <button
                    className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        {/* Social Media and tagline section */}
        <div className="w-full">
          <p className="font-Culpa lg:text-[3rem] text-[2rem] text-center mx-auto leading-[1.3] mb-3">
            Together We Change
          </p>
          <div className="flex justify-center mb-3">
            <div className="bg-white text-green font-bold rounded-full px-3 py-1 text-base">
              Help Our Cause
            </div>
          </div>
          <p className="text-[.9rem] mb-3 text-center font-bold">Follow Us On</p>
          <div className="flex justify-center gap-3">
            <a href="https://www.linkedin.com/company/constantine-foundation/about/?viewAsMember=true">
              <FaLinkedin size={34} />
            </a>
            <a href="https://www.">
              <FaXTwitter size={34} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556522792774">
              <FaFacebook size={34} />
            </a>
            <a href="https://www.">
              <FaYoutube size={34} />
            </a>
            <a href={`mailto:?subject=Check this out&body=${encodeURIComponent(currentUrl)}`}>
              <FaEnvelope size={34} />
            </a>
          </div>
          <button
              className="md:text-xl ml-36 md:ml-16 lg:ml-32 text-[1.2rem] mt-5 text-green bg-white p-2 rounded-full cursor-pointer hover:scale-105"
              onClick={() => setOpen(true)}
            >
              Be Volunteer
            </button>
        </div>
      </div>

      {/* Subscription section */}
      <div className="w-full md:w-[50rem] lg:w-[90rem] mt-4 lg:ml-0 mx-auto">
        <Subscription />
      </div>


      {/* Footer bottom section */}
      <div className="flex flex-col lg:flex-row justify-center lg:gap-40 md:gap-20 gap-10 py-6 text-[.9rem]" style={{ background: "#024331" }}>
        <Link onClick={() => window.scrollTo(0, 0)} to={`/term`}>
          <p className="text-center">Terms of Service</p>
        </Link>
        <p className="text-center">&copy; {currentYear} Constantine Africa Foundation. All Rights Reserved.</p>
        <Link onClick={() => window.scrollTo(0, 0)} to={`/privacy`}>
          <p className="text-center">Privacy Policy</p>
        </Link>
        <div className="flex justify-center items-center mt-6">
          <select
            value={language}
            onChange={(e) => handleLanguageChange(e.target.value)}
            className="bg-primary border border-gray rounded-lg py-2 px-4 text-gray focus:outline-none focus:ring-2 focus:ring-blue focus:border-blue transition duration-300 shadow-sm hover:shadow-md cursor-pointer"
          >
            <option value="en">English</option>
            <option value="ar">Arabic</option>
            <option value="fr">French</option>
            <option value="el">Greek</option>
          </select>
        </div>
        {/* <div id="google_translate_element"></div> */}
      </div>
    </div>
  );
};

export default Footer;
