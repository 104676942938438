import CampainList from "../../components/campaign/campainList";
import Header from "../../components/campaign/detailHeader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Campains = () => {

  return (
    <div>
      <Header />
      <CampainList  />
      
    </div>
  );
};

export default Campains;
