import React, { useState } from "react";
import { AiOutlineFileProtect } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { CiClock2 } from "react-icons/ci";
import { Link } from "react-router-dom";


const DonationProtected = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="relative">
      {/* Button */}
      <button
        className="bg-green text-white px-4 py-2 rounded-lg shadow hover:bg-primary focus:outline-none focus:ring-2 focus:ring-green"
        onClick={openModal}
      >
        <h1 className="flex flex-row">
        <AiOutlineFileProtect className="justify-center m-auto mr-2" />
        Donation Protected
        </h1>
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h3 className="w-32"><AiOutlineFileProtect /></h3>
              <button
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={closeModal}
              >
                ✕
              </button>
            </div>

            {/* Modal Content */}
            <div className="text-sm text-gray-700">
              <h1 className="text-lg">Constantine Foundation</h1>
              <h1 className="text-lg">Giving Guarantee</h1>
              <p className="my-2">
              We guarantee you a full refund in the rare case something isn’t right.
              </p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={"/constantine-guarantee"}
                className="bg-greenshade text-white px-4 py-1 shadow-black shadow-2xl rounded-full"
              >
                 Learn more
              </Link>
              <hr className="my-5" />
              <p className="mt-4 flex flex-row text-start">
                <CiClock2 className="mr-1" />
              Donations of any amount are covered for one full year after you donate.
              </p>
              <p className="mt-4 flex flex-row text-start">
                <TbWorld className="mr-1" />
              Worldwide coverage, wherever you donate from.
              </p>
            </div>

            {/* Modal Footer */}
            <div className="mt-6 flex justify-end">
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonationProtected;
