import React, { useEffect, useState } from "react";
import ContentCard from "../../../components/Card";
import RecentCard from "../../../components/RecentCard";
import axios from "axios";
import Doner from '../../../components/donors';
import NextProject from "../../../pages/project-page/NextProject";

const Body = () => {
  const [Data, setData] = useState(null);

  const url = process.env.REACT_APP_API_URL + `/blogs?populate=*`;
  const headers = {
    Authorization: "bearer" + process.env.REACT_APP_API_TOKEN,
  };

  console.log(url, 'url');

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      console.log(data?.data, 'blog error');
      setData(data?.data);
    };

    fetchData();
  }, [url]);

  return (
    <div className="flex flex-wrap w-full">
      {/* Main content */}
      <div className="flex flex-wrap gap-[1rem] p-[1rem] md:p-[1.5rem] justify-center w-full xl:w-[60%]">
        <ContentCard Data={Data} />
      </div>

      {/* Sidebar content */}
      <div className="p-[1rem] w-full xl:w-[40%]">
        {/* <RecentCard /> */}
        <br />
        {/* <Doner /> */}
        <NextProject />
      </div>
    </div>
  );
};

export default Body;
