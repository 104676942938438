import DetailHead from "../../components/campaign/detailHeader";
import DontationBacground from "../../assets/Constantine_Donate_rm.png";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import x from "../../assets/Logo/bglo.png";
import { motion } from "framer-motion";
import image10 from "../../assets/10 usd.mp4";
import image30 from "../../assets/30.mp4";
import image50 from "../../assets/50 usd.mp4";
import image75 from "../../assets/75 usd.mp4";
import image100 from "../../assets/100 usd.mp4";
import donateImage from "../../assets/donate now.gif.mp4";
import NextCapmaign from "./NextCampaign";

const CampainDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [number, setNumber] = useState(0);
  const [curency, setCurency] = useState("");

  const url = `${process.env.REACT_APP_API_URL}/campaigns/${id}?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });
        setData(data?.data);
      } catch (error) {
        console.error("Error fetching campaign data:", error.message);
      }
    };
    fetchData();
  }, [url]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/projects?populate=*`);
        const projects = response.data.data.map((project) => ({
          id: project.id,
          name: project.attributes.name,
        }));
        setProjectOptions(projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 bg-darkgren pt-12">
      <div className="p-6 md:p-8 col-span-3 bg-darkgren">
        {/* Campaign Title */}
        <div className="text-white text-2xl md:text-3xl lg:text-4xl bg-zinc-300 pl-4 md:pl-5 pr-6 py-4 rounded-lg">
          {Data?.attributes?.name}
        </div>

        {/* Campaign Details */}
        <div className="w-full mt-8 flex flex-col md:flex-row gap-6">
          {/* Campaign Image */}
          <div className="flex-1">
            <img
              loading="lazy"
              src={`${process.env.REACT_APP_API_IMAGE_URL}${Data?.attributes?.imageURL?.data[0]?.attributes?.url}`}
              className="object-cover w-full rounded-lg"
              alt="Campaign"
            />
          </div>

          {/* Campaign Description */}
          <div className="flex-1">
            <div
              style={{ backgroundImage: `url(${x})` }}
              className="text-white text-justify font-sans text-sm md:text-base lg:text-xl bg-zinc-300 px-5 md:px-7 py-4 rounded-lg"
            >
              {Data?.attributes?.right_desc}
            </div>
          </div>
        </div>

        {/* Full Campaign Description */}
        <div className="text-white text-justify font-sans text-sm md:text-base lg:text-xl bg-zinc-300 mt-4 p-5 rounded-lg">
          {Data?.attributes?.description}
        </div>

        {/* Donation Section */}
        <div className="flex w-full gap-10 relative py-[5rem]">
          <div className="lg:h-[30rem] lg:w-[38rem] md:h-[25rem] md:w-[25rem] h-[32rem] w-[20rem] bg-green rounded-3xl lg:p-10 md:p-5 p-3 text-left text-white lg:pt-0 md:pt-0 pt-32">
            <p className="font-roboto font-bold md:text-[1.3rem] text-[1.1rem] my-5 md:mb-10">
              PLACE YOUR DONATION TODAY
            </p>
                          <div className="flex flex-row gap-4">
                          <input
                            type="text"
                            value={`$${number}`}
                            readOnly
                            className="md:w-9/12 w-full mb-6 border-2 font-sans text-2xl bg-green text-white border-white rounded-xl p-3"
                          />
                <select
                  id="showName"
                  value={curency}
                  onChange={(e) => setCurency(e.target.value)}
                  className="md:w-3/12 w-full mb-6 border-2 font-sans text-2xl bg-green text-white border-white rounded-xl p-3"
                >
                  <option value="USD">USD &#36;</option>
                  <option value="EUR">EUR &#8364;</option>
                  {/* <option value="Cedi">Cedi &#8373;</option> */}
                </select>
              </div>
            <select
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              className="md:w-9/12 w-full mb-6 border-2 font-sans text-2xl bg-green text-white border-white rounded-xl p-3"
            >
              <option value="" disabled>
                Please select the purpose of your donation
              </option>
              {projectOptions.map((project) => (
                <option key={project.id} value={project.name}>
                  {project.name}
                </option>
              ))}
            </select>

            <div className="md:w-12/12 w-12/12 mb-6 text-greendark text-center flex gap-2">
              {[image10, image30, image50, image75, image100].map((src, index) => {
                const donationAmount = parseInt(src.match(/\d+/)[0]);
                return (
                  <div
                    key={index}
                    onClick={() => setNumber(donationAmount)}
                    className="rounded-xl p-0 w-2/12"
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      className="object-contain self-stretch w-[100%] h-[100%] mx-auto rounded-full"
                    >
                      <source src={src} type="video/mp4" />
                    </video>
                  </div>
                );
              })}
            </div>

            <div className="md:w-6/12 w-9/12 mb-6 text-center rounded-xl p-0">
              <motion.div whileHover={{ scale: 1.1 }}>
                <video
                  autoPlay
                  muted
                  loop
                  className="object-contain self-stretch w-[80%] h-[100%] mx-auto mt-0 rounded-full cursor-pointer"
                  onClick={() => {navigate("/donate", { state: { selectedProject, number, curency } });
                    window.scrollTo(0, 0);
                  
                }}
                >
                  <source src={donateImage} type="video/mp4" />
                </video>
              </motion.div>
            </div>
          </div>
          <img
                      src={DontationBacground}
                      alt="Donation Background"
                      className="w-full md:w-1/2 object-cover rounded-lg"
                    />
        </div>
      </div>
             {/* Next Campaign Section */}
       <div className="p-4 md:p-8">
         <NextCapmaign />
       </div>
      <div className="w-3/4 m-auto h-1 bg-black"></div>
    </div>
  );
};

export default CampainDetail;
