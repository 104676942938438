/* global google */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Donate from "../components/donors";
import header from "../assets/donation  copy.png";
import DonationProtected from "./DonationProtected.jsx";
import { SiGooglepay } from "react-icons/si";
import { useLocation } from "react-router-dom";
import paymentMtd from "../assets/power payment by Stripe copy.png";
import ProjectCarousel from "./campaign/ProjectCarousel.jsx";
import Swal from "sweetalert2";

const Donater = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { project } = location.state || {}; // Destructure project data
  const { selectedProject, number, curency } = location.state || {};
  const [purposes, setPurposes] = useState([]); // State for purposes list

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "", // Added email field
    amount: location.state?.number || "",
    purpose: location.state?.project || location.state?.selectedProject || "",
    showName: "Yes",
    genzeb: location.state?.curency || "",
});


  // Fetch purposes from Strapi
  useEffect(() => {
    const fetchPurposes = async () => {
      try {
        const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        const purposesData = response.data.data.map((purpose) => ({
          id: purpose.id,
          name: purpose.attributes.name,
        }));
        setPurposes(purposesData);
      } catch (error) {
        console.error("Error fetching purposes:", error);
      }
    };

    fetchPurposes();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  //     // Uncomment to redirect to payment gateway with amount as a query parameter
  //     const paymentUrl = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}?amount=${formData.amount}`;
  //     window.location.href = paymentUrl;
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.amount || !formData.purpose || !formData.genzeb || !formData.showName) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill out all required fields.",
        confirmButtonColor: "#3085d6",
      });
      return;
    }
  
    try {
      // Step 1: Fetch the current budget for the selected purpose
      const purposesUrl = `${process.env.REACT_APP_API_URL}/projects?filters[name][$eq]=${formData.purpose}&populate=*`;
      const purposesResponse = await axios.get(purposesUrl, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      // console.log(purposesResponse.attributes?.data);
  
      if (purposesResponse.data.data.length === 0) {
        // alert("Purpose not found in the backend.");
        Swal.fire({
          icon: "warning",
          title: "Missing Information",
          text: "Purpose not found in the backend.",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
  
      const purposeData = purposesResponse.data.data[0]; // Get the specific purpose
      const currentBudget = purposeData.attributes.budget || 0; // Assume 0 if budget is not set
  
      // Step 2: Calculate the new budget
      const newBudget = currentBudget + parseFloat(formData.amount);
  
      // Step 3: Update the backend with the new budget
      const updateUrl = `${process.env.REACT_APP_API_URL}/projects/${purposeData.id}`;
      const updatePayload = {
        data: {
          budget: newBudget,
        },
      };
  
      await axios.put(updateUrl, updatePayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
  
      // Step 4: Submit the donor details
      const donorPayload = {
        data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email, // Include email in the payload
          amount: formData.amount || location.state?.number,
          purpose: formData.purpose || location.state?.project || location.state?.selectedProject,
          showName: formData.showName === "Yes",
          genzeb: formData.genzeb || location.state?.curency,
        },
      };
  console.table(donorPayload);
      const donorResponse = await axios.post(`${process.env.REACT_APP_API_URL}/donors`,donorPayload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        }
      );
  
      if (donorResponse.status === 200) {
        // alert("Donation successfully submitted and budget updated.");
        Swal.fire({
          icon: "success",
          title: "Donation Successful!",
          html: `<p>Thank you for your generosity! Your donation has been successfully submitted, and the budget has been updated.</p>
                 <p>We appreciate your support in making a difference!</p>`,
          confirmButtonColor: "#28a745",
        });
        const paymentUrl = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}?amount=${formData.amount}`;
        // window.location.href = paymentUrl;
        const sendEmail = () => {
          const emailBody = `
            <p>Dear ${formData.name},</p>
            <p>On behalf of everyone at the Constantine Africa Foundation, I want to express our heartfelt gratitude for your generous donation of <strong>${formData.donationAmount}</strong> received on <strong>${formData.donationDate}</strong>. Your support is invaluable to us and will make a meaningful difference in empowering education, providing clean water, or supporting healthcare initiatives.</p>
            <p>Your contribution allows us to provide essential supplies to children, build sustainable infrastructure, and expand access to medical care. Because of your kindness, we can continue to work towards creating lasting change and opportunities across Africa.</p>
            <p>We’re thrilled to confirm that your donation has been processed successfully. For your records, here are the details:</p>
            <ul>
              <li><strong>Donation Amount:</strong> ${formData.amount}</li>
              <li><strong>Date:</strong> ${formData.donationDate}</li>
              <li><strong>Transaction ID:</strong> ${formData.transactionId}</li>
            </ul>
            <p>You can learn more about the impact of your support by visiting our website or following us on our social media channels. Together, we are making a difference!</p>
            <p>Thank you once again for your generosity and for being a part of this journey. Should you have any questions or need additional information, please feel free to reach out to us at <a href="mailto:info@constantinefoundation.org">info@constantinefoundation.org</a>.</p>
            <p>With gratitude,</p>
            <p><strong>[Your Full Name]</strong><br/>
            [Your Title/Position]<br/>
            Constantine Africa Foundation<br/>
            <a href="https://constantinefoundation.org">constantinefoundation.org</a> | [Social Media Links]</p>
          `;
        
          window.Email.send({
            Host: "smtp.your-email-provider.com",
            Username: "info@constantinefoundation.org",
            Password: "0~xJRZ_q^4n7",
            To: `${formData.email}`,
            From: "info@constantinefoundation.org",
            Subject: "Thank You for Your Generous Donation!",
            Body: emailBody,
          }).then((message) => alert(message));
        };
        
        // sendEmail();
      }
    } catch (error) {
      console.error("Error submitting donation or updating budget:", error);
      // alert("An error occurred while processing your donation.");
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "An error occurred while processing your donation. Please try again.",
        confirmButtonColor: "#d33",
      });
    }
  };  

  const handleGooglePay = () => {
    if (!formData.amount) {
      alert("Please enter a donation amount.");
      return;
    }

    if (!window.google) {
      alert("Google Pay script not loaded. Please try again later.");
      return;
    }

    const googlePayClient = new google.payments.api.PaymentsClient({ environment: "TEST" });

    const paymentDataRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["AMEX", "MASTERCARD", "VISA"],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "example",
              gatewayMerchantId: "exampleMerchantId",
            },
          },
        },
      ],
      merchantInfo: {
        merchantName: "Constantine Foundation",
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPrice: formData.amount,
        currencyCode: "USD",
      },
    };

    googlePayClient
      .isReadyToPay({ allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods })
      .then((response) => {
        if (response.result) {
          googlePayClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
            console.log("Payment Success:", paymentData);
            alert("Thank you for your donation!");
          });
        } else {
          alert("Google Pay is not available.");
        }
      })
      .catch((err) => console.error("Error initializing Google Pay:", err));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("Submit donation form logic");
  // };

  return (
    <React.Fragment>
      {/* Header Section */}
      <div
        className="relative lg:h-[52rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end"
        id="header"
      >
        <img
          src={header}
          alt="header"
          className="absolute inset-0 -z-10 w-full h-full object-cover object-right md:object-center"
        />
      </div>
      <div className="text-center mt-10 text-2xl text-darkgreen">
        <h1>"Be the Light of Change – Support the Constantine Foundation Today!"</h1>
      </div>
      <div className="flex flex-col md:flex-col lg:flex-row">
        <section className="w-[100%] md:w-[100%] lg:w-[70%] p-6 mx-auto md:mx-auto lg:mx-10 bg-green rounded-md shadow-md my-5">
          <h1 className="text-xl font-bold text-white capitalize dark:text-white">Donation form</h1>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="firstName">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  type="text"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="amount">
                  Amount
                </label>
                <input
                  id="amount"
                  type="number"
                  value={formData.amount || number}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="purpose">
                  Purpose of donation
                </label>
                <select
                  id="purpose"
                  value={formData.purpose || project || selectedProject} // Use {project} if no value is selected
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                >
                  <option value="" disabled>
                    Select purpose
                  </option>
                  {purposes.map((purpose) => (
                    <option key={purpose.id} value={purpose.name}>
                      {purpose.name}
                    </option>
                  ))}
                </select>

              </div>
              {/* i should do the back end for this */}
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="curency">
                  Choose your currency method {curency}  is selected.
                </label>
                <select
                  id="genzeb"
                  value={formData.genzeb || curency} 
                  placeholder={curency}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                >
                  <option>USD</option>
                  <option>EUR</option>
                  {/* <option>Cedi</option> */}
                </select>
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="showName">
                  Would you like to show your name on the website?
                </label>
                <select
                  id="showName"
                  value={formData.showName}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                >
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
              <div className="flex justify-end mt-6">
            </div>
            </div>
            <button type="Submit" className="p-4 hover:p-6 text-5xl shadow-2xl shadow-black w-[25%] ml-20 mt-8 bg-white text-primary hover:bg-primary hover:text-white rounded-full">
                Donate
            </button>
          </form>
          <div className="mt-4">
            {/* <button
              className="px-6 flex flex-row py-2 my-auto font-sans bg-black text-white rounded-md hover:opacity-75"
              onClick={handleGooglePay}
            >
              Donate with
              <SiGooglepay className="my-auto mx-2" size={30} />
            </button> */}
            <img src={paymentMtd} className="h-auto ml-[20rem] mt-12 pb-[1rem] w-2/3" alt="" />
          </div>

        </section>
        <div className="mt-[4rem] w-[100%] md:w-[100%] lg:w-[30%] shadow-xl shadow-black p-6 mx-auto md:mx-auto lg:mx-10">
          {/* <Donate /> */}
          <ProjectCarousel />
        </div>
      </div>
      <div className="text-center mb-10 w-[100%]">
        <DonationProtected />
      </div>
    </React.Fragment>
  );
};

export default Donater;
